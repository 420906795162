import React from 'react';
import { Field, CccisdInput, CccisdTextarea, CccisdWysiwyg } from 'cccisd-formik';
import { BuilderBlock, reportTemplateBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    title: 'Calculation Report',
    description:
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
    mainTableTitle: 'Total Cost of All Interventions',
    PBISTableTitle: 'Positive Behavioral Interventions and Supports',
    SELPTableTitle: 'Social Emotional Learning Program',
    BPPTableTitle: 'Bulling Prevention Program',
    OT1TableTitle: 'Other Tier 1 Program',
    T2TableTitle: 'Tier 2 Program',
    T3TableTitle: 'Tier 3 Program',
    SDPTableTitle: 'Mentoring',
    otherTableTitle: 'Other Type',
    rowText: {
        TextR1: 'Training Cost',
        TextR2: 'Staff Intervention Time Cost',
        TextR3: 'Parent Time Cost',
        TextR4: 'Volunteer Time and Materials Cost',
    },
    citationLabel: 'Suggested Citation',
    citation:
        'Bradshoaw, C.P., Herman, K.C., Johnson, S.L., & Reinke W. M. (2020). School of Mental Health Intervention Cost Calculator. NCRSMH: National Center for School Mental Health. Retrieved From: https://www.ruralsmh.com/cost-calculator',
    filterFields: [],
    otherFilterOptions: [],
    hideFilterBar: true,
};

const Component = () => {
    return (
        <>
            <BuilderBlock title="Header">
                <Field name="title" component={CccisdInput} label="Title" />
                <Field name="description" component={CccisdWysiwyg} label="Description" />
            </BuilderBlock>
            <BuilderBlock title="Table Titles">
                <Field name="mainTableTitle" component={CccisdInput} label="Main Table - Title" />
                <Field
                    name="PBISTableTitle"
                    component={CccisdInput}
                    label="Additonal Program Type 1 - Title"
                />
                <Field
                    name="SELPTableTitle"
                    component={CccisdInput}
                    label="Additonal Program Type 2 - Title"
                />
                <Field
                    name="BPPTableTitle"
                    component={CccisdInput}
                    label="Additonal Program Type 3 - Title"
                />
                <Field
                    name="OT1TableTitle"
                    component={CccisdInput}
                    label="Additonal Program Type 4 - Title"
                />
                <Field
                    name="T2TableTitle"
                    component={CccisdInput}
                    label="Additonal Program Type 5 - Title"
                />
                <Field
                    name="T3TableTitle"
                    component={CccisdInput}
                    label="Additonal Program Type 6 - Title"
                />
                <Field
                    name="SDPTableTitle"
                    component={CccisdInput}
                    label="Additonal Program Type 7 - Title"
                />
                <Field
                    name="otherTableTitle"
                    component={CccisdInput}
                    label="Additonal Program Type 8 - Title"
                />
            </BuilderBlock>
            <BuilderBlock title="Table Row Text">
                <Field name="rowText.TextR1" component={CccisdInput} label="Table Row 1 Text" />
                <Field name="rowText.TextR2" component={CccisdInput} label="Table Row 2 Text" />
                <Field name="rowText.TextR3" component={CccisdInput} label="Table Row 3 Text" />
                <Field name="rowText.TextR4" component={CccisdInput} label="Table Row 4 Text" />
            </BuilderBlock>
            <BuilderBlock title="Citation">
                <Field name="citationLabel" component={CccisdInput} label="Label" />
                <Field name="citation" component={CccisdTextarea} label="Citation Text" />
            </BuilderBlock>
        </>
    );
};

// reportTemplateBuilder is a Formik wrapper which combines the Fields in this component with Filter and Widget Fields.
export default reportTemplateBuilder({})(Component);
