import React from 'react';
import { Formik, Form, Field, CccisdInput } from 'cccisd-formik';
import style from './style.css';

const HoursForm = props => {
    const { initialValues, onSubmit, handle } = props;
    const validate = values => {
        const errors = {};
        let regex = /^[0-9]*$/;

        if (!values.NumAdmins || !regex.test(+values.NumAdmins)) {
            errors.NumAdmins = 'A number is required';
        }
        if (!values.HoursAdmins || !regex.test(+values.HoursAdmins)) {
            errors.HoursAdmins = 'A number is required';
        }
        if (!values.NumSMHP || !regex.test(+values.NumSMHP)) {
            errors.NumSMHP = 'A number is required';
        }
        if (!values.HoursSMHP || !regex.test(+values.HoursSMHP)) {
            errors.HoursSMHP = 'A number is required';
        }
        if (!values.NumTeachers || !regex.test(+values.NumTeachers)) {
            errors.NumTeachers = 'A number is required';
        }
        if (!values.HoursTeachers || !regex.test(+values.HoursTeachers)) {
            errors.HoursTeachers = 'A number is required';
        }
        if (!values.NumOthers || !regex.test(+values.NumOthers)) {
            errors.NumOthers = 'A number is required';
        }
        if (!values.HoursOthers || !regex.test(+values.HoursOthers)) {
            errors.HoursOthers = 'A number is required';
        }

        return errors;
    };

    const submit = values => {
        const newValues = {
            ...values,
            HoursAdmins: +values.HoursAdmins,
            HoursOthers: +values.HoursOthers,
            HoursSMHP: +values.HoursSMHP,
            HoursTeachers: +values.HoursTeachers,
        };

        onSubmit(newValues, handle);
        props.closeModal();
    };

    return (
        <Formik
            validate={validate}
            onSubmit={submit}
            initialValues={initialValues}
            render={() => (
                <div className={style.formWrapper}>
                    <Form>
                        <table className={`${style.formTable} table-striped table-bordered`}>
                            <thead>
                                <tr>
                                    <th />
                                    <th>How many staff?</th>
                                    {/* Change 4/2022 hours -> minutes  */}
                                    <th>How many minutes per staff?</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Administrators</td>
                                    <td>
                                        <Field
                                            name="NumAdmins"
                                            type="text"
                                            component={CccisdInput}
                                            label=""
                                        />
                                    </td>
                                    <td>
                                        <Field
                                            name="HoursAdmins"
                                            type="text"
                                            component={CccisdInput}
                                            label=""
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>School Mental Health Professionals</td>
                                    <td>
                                        <Field
                                            name="NumSMHP"
                                            type="text"
                                            component={CccisdInput}
                                            label=""
                                        />
                                    </td>
                                    <td>
                                        <Field
                                            name="HoursSMHP"
                                            type="text"
                                            component={CccisdInput}
                                            label=""
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Teachers</td>
                                    <td>
                                        <Field
                                            name="NumTeachers"
                                            type="text"
                                            component={CccisdInput}
                                            label=""
                                        />
                                    </td>
                                    <td>
                                        <Field
                                            name="HoursTeachers"
                                            type="text"
                                            component={CccisdInput}
                                            label=""
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Others</td>
                                    <td>
                                        <Field
                                            name="NumOthers"
                                            type="text"
                                            component={CccisdInput}
                                            label=""
                                        />
                                    </td>
                                    <td>
                                        <Field
                                            name="HoursOthers"
                                            type="text"
                                            component={CccisdInput}
                                            label=""
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <button className="btn btn-primary" type="submit">
                            Submit
                        </button>
                    </Form>
                </div>
            )}
        />
    );
};

export default HoursForm;
