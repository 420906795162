import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import style from './style.css';

import logoUofMiss from '../../../images/footer-logos/UniversityOfMissouri_white400x75.png';
import logoUofMont from '../../../images/footer-logos/UniversityOfMontana_white400x75.png';
import logoUofVirg from '../../../images/footer-logos/UniversityOfVirginia_white400x75.png';
import IESLogo from '../../../images/footer-logos/IES-Institute_of_Education_Sciences.png';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const getWpUrl = (path = '') => 'https://www.ruralsmh.com/' + path;

export default class SiteFooter extends React.Component {
    getYear = () => {
        const date = new Date().getFullYear();
        return date;
    };

    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: 'container',
    };

    getLiLink = (key, url, target, link) => {
        return (
            <a key={key} href={url} target={target}>
                {link}
            </a>
        );
    };

    render() {
        const wpLinks = [
            this.getLiLink('home', getWpUrl(''), '_top', 'Welcome'),
            this.getLiLink('about', getWpUrl('about-us'), '_top', 'About Us'),
            this.getLiLink('resources', Boilerplate.url('/resourceCenter'), '_top', 'Resources'),
            this.getLiLink('contact', getWpUrl('contact'), '_top', 'Contact'),
        ];

        return (
            <>
                <div className={classnames('container-fluid', style.siteFooter)}>
                    <div className={classnames('container', style.content)}>
                        <div className={style.iesBox}>
                            <img alt="University of Missouri" src={IESLogo} />{' '}
                            <p className={classnames(style.textRow, style.row)}>
                                NCRSMH was funded by the Institute of Education Sciences, U.S.
                                Department of Education, through Grant R305C190014 to the University
                                of Missouri.
                            </p>
                        </div>

                        <div className={classnames(style.logoRow, style.row)}>
                            <img alt="University of Missouri" src={logoUofMiss} />
                            <img alt="University of Virginia" src={logoUofVirg} />
                            <img alt="University of Montana" src={logoUofMont} />
                        </div>
                        <div className={classnames(style.linkRow, style.row)}>{wpLinks}</div>
                    </div>
                </div>

                <div className={style.cccFooter}>
                    <span>
                        Website and software developed by{' '}
                        <a
                            style={{ color: '#fff', textDecoration: 'underline' }}
                            href="https://www.3cisd.com"
                        >
                            3C Institute
                        </a>{' '}
                        © {this.getYear()} 3C Institute. All rights reserved.
                    </span>
                    <span>
                        <a style={{ color: '#fff' }} href="https://www.3cisd.com#ccc-lets-talk">
                            Contact Us
                        </a>
                        <a
                            style={{ marginLeft: '1em', color: '#fff' }}
                            href="https://www.3cisd.com/terms-of-use"
                        >
                            Terms of Use
                        </a>
                        <a
                            style={{ marginLeft: '1em', color: '#fff' }}
                            href="https://www.3cisd.com/privacy-policy"
                        >
                            Privacy Policy
                        </a>
                    </span>
                </div>
            </>
        );
    }
}
