import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'costCalcReport',
    label: 'Cost Cacluator Report',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
