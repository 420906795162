import React from 'react';
import Arrow from 'cccisd-icons/undo2';
import axios from 'cccisd-axios';
import Loader from 'cccisd-loader';
import { client } from 'cccisd-apollo';
import _isEmpty from 'lodash/isEmpty';
import Select from './Select';
import SurveyRender from './SurveyRender';
import ReportRender from './ReportRender';
import RegisterLogin from './RegisterLogin';
import style from './style.css';
import progressQuery from './pastSurveys.graphql';
import deploymentQuery from './deploymentQuery.graphql';
import _omit from 'lodash/omit';
import Modal from 'cccisd-modal';

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

class CostCalcPage extends React.Component {
    state = {
        user: null,
        deploymentId: null,
        // display: 'survey',
        showBanner: false,
        startNew: false,
        loading: true,
    };

    modal = React.createRef();

    componentDidMount = async () => {
        const loggedIn = Fortress.user.acting.data_type !== 'guest';

        let pawnId = Fortress.user.acting.id;
        let pawnHash = Fortress.user.acting.random_hash;
        let respondentHash = Fortress.user.acting.respondent_hash;
        let deployment = null;
        // If Guest then create temporary pawnId to save metrics
        if (!loggedIn) {
            const deploymentId = await this.getDeployment();

            const guestInfo = await this.createAndSetAnon(deploymentId);

            deployment = deploymentId;
            pawnId = guestInfo.pawnId;
            pawnHash = guestInfo.pawnHash;
            respondentHash = guestInfo.respondentHash;
            this.setState({
                isGuest: true,
            });
        }

        const progressData = await this.getProgressData(pawnId);

        const flowProgressList = progressData.data.roles.anyRole.flowProgressList;

        // let data = fakeData;

        const noProgress = _isEmpty(flowProgressList);

        if (noProgress && !this.state.isGuest) {
            const deploymentId = await this.getDeployment();
            deployment = deploymentId;
        }

        const allComplete = this.allComplete(flowProgressList);

        const currentSurvey = allComplete
            ? flowProgressList[0]
            : flowProgressList.find(item => !item.completed);

        const bannerState = this.getBannerState(!this.state.isGuest, flowProgressList);

        let list = flowProgressList.filter(
            item => item.flowHandle === 'survey13' && item.completed
        );

        list.unshift({ responseSet: 0, completedDate: '-- Select --' });

        this.setState({
            user: {
                pawnId,
                pawnHash,
                respondentHash,
            },
            deploymentId: noProgress ? deployment : currentSurvey.responseSet,
            surveyList: noProgress ? null : list,
            progressList: flowProgressList,
            ...bannerState,
            loading: false,
        });
    };

    getFirstInProgress = flowProgressList => {
        let first;

        const startedList = flowProgressList.filter(
            item => item.flowHandle === 'survey6' && item.started
        );
        const completedList = flowProgressList.filter(
            item => item.flowHandle === 'survey13' && item.completed
        );

        startedList.forEach(item => {
            const match = completedList.find(thing => thing.responseSet === item.responseSet);
            if (!match) {
                first = item;
            }
        });

        if (first) {
            return first;
        }
    };

    getDeployment = async () => {
        const response = await client.query({
            query: deploymentQuery,
            fetchPolicy: 'network-only',
            variables: {
                // MAY NEED TO CHANGE
                deploymentHandle: 'costCalculator',
            },
        });

        return response.data.flows.deployment.deploymentId;
    };

    getProgressData = async pawnId => {
        const response = await client.query({
            query: progressQuery,
            variables: {
                pawnId,
            },
            fetchPolicy: 'network-only',
        });

        return response;
    };

    createAndSetAnon = async deploymentId => {
        try {
            let response = await axios.post(
                Boilerplate.route('api.assignmentDeployment.addDeploymentAnon', {
                    deploymentId,
                })
            );
            return {
                pawnId: response.data.data.id,
                pawnHash: response.data.data.random_hash,
                respondentHash: response.data.data.respondent_hash,
            };
        } catch (error) {
            console.error(error);
        }
    };

    getBannerState = (loggedIn, selectList) => {
        // Guest
        if (!loggedIn) {
            return {
                display: 'survey',
                showBanner: true,
                bannerState: {
                    showSelect: false,
                    showResume: false,
                    loggedIn,
                    isGuest: true,
                },
            };
        }
        // loggedIn
        const noProgress = _isEmpty(selectList);

        if (!noProgress) {
            const startedList = selectList.filter(item => item.flowHandle === 'survey6');
            const completedList = selectList.filter(
                item => item.flowHandle === 'survey13' && item.completed
            );

            let matchList = [];

            startedList.forEach(survey => {
                const match = completedList.find(item => item.responseSet === survey.responseSet);
                if (match) {
                    matchList.push(match);
                }
            });

            const allComplete =
                matchList.length === startedList.length && this.allComplete(selectList);

            if (allComplete) {
                return {
                    display: 'report',
                    showBanner: completedList.length > 0 ? true : false,
                    bannerState: {
                        showSelect: completedList.length > 0 ? true : false,
                        showResume: false,
                        loggedIn,
                    },
                };
            }

            // in Progress
            return {
                display: 'survey',
                showBanner: completedList.length > 0,
                bannerState: {
                    showSelect: completedList.length > 0 ? true : false,
                    showResume: this.state.display === 'report',
                    loggedIn,
                },
            };
        }
        // No History
        return {
            display: 'survey',
            showBanner: false,
            bannerState: {
                showSelect: false,
                showResume: false,
                loggedIn,
            },
        };
    };

    getStyle = () => {
        const { showBanner } = this.state;
        const loggedIn = !this.state.isGuest;

        if (!showBanner) {
            return style.noBanner;
        }
        if (loggedIn) {
            return style.displayAreaLoggedIn;
        }
        return style.displayAreaLoggedOut;
    };

    allComplete = array => {
        if (array.every(item => item.completed)) {
            return true;
        }
        return false;
    };

    resumeSurvey = () => {
        this.updateBannerState();
        const { progressList } = this.state;
        const firstInProgress = this.getFirstInProgress(progressList);

        this.setState({
            display: 'survey',
            deploymentId: firstInProgress.responseSet,
        });
    };

    updateBannerState = () => {
        this.getProgressData(this.state.user.pawnId).then(result => {
            const flowProgressList = result.data.roles.anyRole.flowProgressList;
            const loggedIn = !this.state.isGuest;
            const bannerState = this.getBannerState(loggedIn, flowProgressList);
            const modifiedBannerState = _omit(bannerState, ['display']);

            this.setState({
                progressList: flowProgressList,
                ...modifiedBannerState,
            });
        });
    };

    continueAsGuest = () => {
        const { continueFrom } = this.state;
        this.setState({ display: continueFrom, showBanner: true });
    };

    startNewSurvey = () => {
        // Start button on report
        this.setState({ startNew: true, display: 'survey' }, () => {
            this.updateBannerState();
        });
    };

    editCurrentSurvey = () => {
        // edit button
        this.setState({
            display: 'survey',
            bannerState: { showResume: false, loggedIn: !this.state.isGuest, showSelect: true },
        });
    };

    viewResponse = () => {
        this.setState({ showResponse: true });
        this.modal.current.open();
    };

    resetStartNew = id => {
        // reset startNew to false on new survey begin
        this.setState({ startNew: false, newDeploymentId: id });
    };

    returnToReport = () => {
        this.setState({ display: 'report' });
    };

    onSelectionChange = event => {
        if (+event.target.value !== 0) {
            const { surveyList } = this.state;
            const match = surveyList.find(item => item.responseSet === +event.target.value);
            if (match) {
                this.setState({
                    deploymentId: match.responseSet,
                    display: 'report',
                    newDeploymentId: null,
                });
                this.updateBannerState();
            }
        }
    };

    onComplete = async deploymentId => {
        // Survey complete
        const {
            user: { pawnId },
        } = this.state;

        const loggedIn = !this.state.isGuest;

        this.setState({ display: 'report' });

        const progressData = await this.getProgressData(pawnId);
        const flowProgressList = progressData.data.roles.anyRole.flowProgressList;
        const bannerState = this.getBannerState(loggedIn, flowProgressList);
        const modifiedBannerState = _omit(bannerState, ['display']);

        const list = flowProgressList.filter(
            item => item.flowHandle === 'survey13' && item.completed
        );
        list.unshift({ responseSet: 0, completedDate: '-- Select --' });

        this.setState({
            deploymentId,
            progressList: flowProgressList,
            surveyList: list,
            ...modifiedBannerState,
        });
    };

    onLoginCLick = () => {
        const { display } = this.state;
        this.setState({ display: 'login', continueFrom: display, showBanner: false });
    };

    renderSelect = () => {
        const { surveyList } = this.state;

        return (
            <Select
                options={surveyList}
                onSelectionChange={this.onSelectionChange}
                newDeploymentId={this.state.newDeploymentId}
                value={this.state.deploymentId ? +this.state.deploymentId : 0}
            />
        );
    };

    renderBanner = () => {
        const {
            bannerState: { loggedIn, showSelect, showResume },
        } = this.state;
        return (
            <div className={style.bannerWrapper}>
                {loggedIn ? (
                    <div className={style.bannerLoggedIn}>
                        <div className={style.resume}>
                            {showResume && (
                                <button
                                    type="button"
                                    className={`btn btn-default ${style.resumeButton}`}
                                    onClick={this.resumeSurvey}
                                >
                                    <Arrow /> Resume Current Survey
                                </button>
                            )}
                        </div>
                        <div className={style.select}>
                            {showSelect && this.state.surveyList.length > 0 && (
                                <>
                                    <strong>Past Reports: </strong>
                                    {this.renderSelect()}
                                </>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className={style.bannerGuest}>
                        <div className={style.bannerText}>
                            <strong>Want to save your data?</strong> Log in or create an account to
                            have access to your cost calculator data later. If you leave this page
                            without logging in, your data will be lost.
                        </div>
                        <div className={style.bannerLogin}>
                            <button
                                type="button"
                                className={`btn btn-default ${style.accountButton}`}
                                onClick={this.onLoginCLick}
                            >
                                Create an Account
                            </button>
                            <div className={style.loginText}>
                                Have an account? <a onClick={this.onLoginCLick}>Log In</a>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    renderSurvey = () => {
        const { startNew, deploymentId, user, surveyList, showResponse } = this.state;

        return (
            <SurveyRender
                deploymentId={deploymentId}
                startNew={startNew}
                onComplete={this.onComplete}
                resetStartNew={this.resetStartNew}
                user={user}
                surveyList={surveyList}
                isPrintMode={showResponse}
                returnToReport={this.returnToReport}
            />
        );
    };

    renderReport = () => {
        const { deploymentId, user, surveyList, isGuest, bannerState, progressList } = this.state;

        return (
            <ReportRender
                handle="costCalcReport" // MAY CHANGE
                deploymentId={deploymentId}
                startNewSurvey={this.startNewSurvey}
                progressList={progressList}
                editCurrentSurvey={this.editCurrentSurvey}
                viewResponse={this.viewResponse}
                user={user}
                resumeOn={bannerState.showResume}
                loggedIn={!isGuest}
                surveyList={surveyList}
            />
        );
    };

    renderModal = () => {};

    render() {
        const { startNew, deploymentId, user } = this.state;
        return (
            <Loader loading={this.state.loading} removeChildren>
                {this.state.showBanner && this.renderBanner()}
                <Modal
                    size="large"
                    ref={this.modal}
                    beforeClose={() => {
                        this.setState({ showResponse: false });
                    }}
                >
                    <div className={style.fixContainer}>
                        <SurveyRender
                            deploymentId={deploymentId}
                            startNew={startNew}
                            onComplete={this.onComplete}
                            resetStartNew={this.resetStartNew}
                            user={user}
                            isPrintMode={this.state.showResponse}
                            returnToReport={this.returnToReport}
                        />
                    </div>
                </Modal>

                <div className={this.getStyle()}>
                    {this.state.display === 'survey' && (
                        <div className={style.surveySection}>
                            {this.state.deploymentId && this.renderSurvey()}
                        </div>
                    )}
                    {this.state.display === 'report' && (
                        <div
                            className={
                                this.state.showBanner
                                    ? style.reportSection
                                    : style.reportSectionNoBanner
                            }
                        >
                            {this.state.deploymentId && this.renderReport()}
                        </div>
                    )}
                    {this.state.display === 'login' && (
                        <RegisterLogin
                            continueAsGuest={this.continueAsGuest}
                            user={user}
                            deploymentId={deploymentId}
                        />
                    )}
                </div>
            </Loader>
        );
    }
}

export default CostCalcPage;
