import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import axios from 'cccisd-axios';

const Boilerplate = window.cccisd.boilerplate;

const SurveyRender = props => {
    const [loading, setLoading] = useState(true);
    const [deploymentId, setDeploymentId] = useState(null);

    useEffect(() => {
        let mounted = true;
        async function getData() {
            await repeatedDeployment();
        }

        if (mounted) {
            if (props.startNew) {
                getData();
            } else {
                setDeploymentId(props.deploymentId);
                setLoading(false);
            }
        }
        return function cleanUp() {
            mounted = false;
        };
    }, []);

    const repeatedDeployment = async () => {
        const { user } = props;

        let response = await axios.get(
            Boilerplate.route('api.assignmentDeployment.repeated', {
                deploymentId: 1,
                pawnId: user.pawnId,
                hash: user.respondentHash,
            })
        );
        const responseSet =
            response.data.data.deploymentId < props.deploymentId
                ? props.deploymentId
                : response.data.data.deploymentId;

        setDeploymentId(responseSet);
        props.resetStartNew(responseSet);
        setLoading(false);
    };

    const renderSurvey = () => {
        const {
            user: { pawnId, pawnHash },
        } = props;
        return (
            <DeploymentPlayer
                deploymentId={deploymentId}
                pawnId={pawnId}
                pawnHash={pawnHash}
                onComplete={() => {
                    props.onComplete(deploymentId);
                }}
                flowProps={{ hidePlayButton: true, isPrintMode: props.isPrintMode }}
            />
        );
    };

    return (
        <Loader loading={loading} removeChildren>
            {renderSurvey()}
        </Loader>
    );
};

SurveyRender.propTypes = {
    deploymentId: PropTypes.number,
    startNew: PropTypes.bool,
    onComplete: PropTypes.func,
    resetStartNew: PropTypes.func,
    user: PropTypes.object,
    surveyList: PropTypes.array,
    isPrintMode: PropTypes.bool,
    returnToReport: PropTypes.func,
};

export default SurveyRender;
