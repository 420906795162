import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import ArrowClosed from 'cccisd-icons/arrow-right20';
import ArrowOpen from 'cccisd-icons/arrow-down20';
import { BrowserOnly, PrintOnly } from 'cccisd-laravel-report';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import Modal from 'cccisd-modal';
import Pencil from 'cccisd-icons/pencil7';
import HoursForm from './HoursForm';

class RenderTables extends React.Component {
    state = {
        open: true,
        studentInput: 0,
        hasFormInput: false,
    };

    formModal = React.createRef();

    componentDidMount = () => {
        const counts = this.getCountsFromData(this.props.data);

        this.setState({
            data: this.props.data,
            studentInput: counts.studentCount,
        });
    };

    componentDidUpdate = (prevProps, prevState) => {
        const counts = this.getCountsFromData(this.props.data);

        if (prevProps.currentStudents && !this.props.currentStudents && !this.props.isRecalc) {
            this.setState({
                studentInput: counts.studentCount,
            });
        }
        if (prevProps.currentFormInput && !this.props.currentFormInput && !this.props.isRecalc) {
            this.setState({
                studentInput: counts.studentCount,
                hasFormInput: false,
            });
        }
        if (!_isEqual(prevProps.data, this.props.data)) {
            this.setState({
                data: this.props.data,
                studentInput:
                    this.props.isRecalc && this.props.recalcData && this.props.recalcData.students
                        ? this.props.recalcData.students
                        : counts.studentCount,
                hasFormInput: false,
            });
            if (!_isEqual(prevProps.recalcData, this.props.recalcData)) {
                this.setState({
                    recalcData: this.props.recalcData,
                });
            }
        }

        if (prevProps.openAll !== this.props.openAll && this.props.openAll) {
            this.setState({
                open: this.props.openAll,
            });
            setTimeout(() => {
                this.setState({ open: prevState.open });
                this.props.resetOpenAll();
            }, 3000);
        }
    };

    getCountsFromData = data => {
        const { studentCountTag, hoursCountTag } = this.props.tableConfig;
        const {
            scoring,
            graphql: { reportMetrics },
        } = data;

        let totalHours = scoring[hoursCountTag] ? +scoring[hoursCountTag] / 60 : 0;
        const roundedHours = Math.round(totalHours * 10) / 10;

        const totalStudents = reportMetrics[studentCountTag] ? reportMetrics[studentCountTag] : 0;

        return {
            studentCount: totalStudents,
            // Change 4/2022 hours -> minutes
            hoursCount: roundedHours,
        };
    };

    getTableData = () => {
        // Aseemble Table Data
        const { TextR1, TextR2, TextR3, TextR4 } = this.props.settings.rowText;

        const row1 = [TextR1];
        const row2 = [TextR2];

        const row3 = [TextR3];
        const row4 = [TextR4];
        const row5 = ['TOTAL COST'];

        if (this.props.displayWage === 'state') {
            this.props.tableConfig.stateValues.forEach(tag => {
                if (this.props.index !== 0) {
                    if (tag.includes('Training')) {
                        row1.push(tag);
                    }
                    if (tag.includes('Staff')) {
                        row2.push(tag);
                    }
                    if (tag.includes('Parent')) {
                        row3.push(tag);
                    }
                    if (tag.includes('Volunteer')) {
                        row4.push(tag);
                    }
                } else {
                    if (tag.includes('training')) {
                        row1.push(tag);
                    }
                    if (tag.includes('staff')) {
                        row2.push(tag);
                    }
                    if (tag.includes('parent')) {
                        row3.push(tag);
                    }
                    if (tag.includes('volunteer')) {
                        row4.push(tag);
                    }
                }
            });
        }
        if (this.props.displayWage === 'national') {
            this.props.tableConfig.nationalValues.forEach(tag => {
                if (this.props.index !== 0) {
                    if (tag.includes('Training')) {
                        row1.push(tag);
                    }
                    if (tag.includes('Staff')) {
                        row2.push(tag);
                    }
                    if (tag.includes('Parent')) {
                        row3.push(tag);
                    }
                    if (tag.includes('Volunteer')) {
                        row4.push(tag);
                    }
                } else {
                    if (tag.includes('training')) {
                        row1.push(tag);
                    }
                    if (tag.includes('staff')) {
                        row2.push(tag);
                    }
                    if (tag.includes('parent')) {
                        row3.push(tag);
                    }
                    if (tag.includes('volunteer')) {
                        row4.push(tag);
                    }
                }
            });
        }

        return [row1, row2, row3, row4, row5];
    };

    getTitle = () => {
        const devTags = this.props.data.graphql.reportMetrics;
        const subTitle = devTags[this.props.tableConfig.subTitleTag];

        return (
            <h4>
                {this.props.settings[this.props.tableConfig.titleHandle]}
                {subTitle && (
                    <>
                        {' '}
                        -{' '}
                        <span className={style.subTitle}>
                            <i>{subTitle}</i>
                        </span>
                    </>
                )}
            </h4>
        );
    };

    // Edit Later
    getValue = tag => {
        let metrics = this.state.data.scoring;

        const cost = metrics[tag];

        if (cost) {
            return +cost;
        }
        return 0;
    };

    getInitialValues = handle => {
        const tags = this.props.tableConfig.initialFormValues;
        const metrics = this.props.data.graphql.initialValues;

        let initialValues = {};
        if (tags) {
            for (const [key, value] of Object.entries(tags)) {
                if (metrics[value]) {
                    initialValues[key] = metrics[value];
                } // stuff
            }
        }

        return initialValues;
    };

    handleClick = () => {
        this.setState({ open: !this.state.open });
    };

    handleInputChange = (event, type) => {
        event.preventDefault();
        if (type === 'student') {
            this.setState({
                studentInput: event.target.value,
            });
            this.props.saveCounts(this.props.tableConfig.handle, event.target.value);
        }
    };

    closeModal = () => {
        this.formModal.current.close();
    };

    onFormSubmit = (values, key) => {
        this.props.onHoursFormSubmit(values, key);
        const initialValues = this.getInitialValues();
        const recalcValues = this.props.recalcData;
        const compareValues = !_isEmpty(recalcValues) ? recalcValues : initialValues;

        if (_isEqual(values, compareValues)) {
            this.setState({
                hasFormInput: false,
            });
        } else {
            this.setState({
                hasFormInput: true,
            });
        }
    };

    renderCardHeader = () => {
        const isMainTable = this.props.index === 0;
        return (
            <div
                className={`${style.tableHeader} ${
                    isMainTable ? style.mainHeader : style.collapseHeader
                } ${!this.state.open && style.closed}`}
            >
                <div>{this.getTitle()}</div>
                <BrowserOnly>
                    {!isMainTable && (
                        <div
                            className={style.rightSide}
                            onClick={() => {
                                this.handleClick();
                            }}
                        >
                            {this.state.open ? <ArrowOpen /> : <ArrowClosed />}
                        </div>
                    )}
                </BrowserOnly>
            </div>
        );
    };

    renderTableHead = () => {
        return (
            <thead>
                <tr>
                    <th>Cost Breakdown</th>
                    <th>Cost/Student</th>
                    <th>Cost/Year</th>
                </tr>
            </thead>
        );
    };

    numberWithCommas = number => {
        const roundedNumber = Math.round(number * 100) / 100;

        return roundedNumber
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    renderTableBody = () => {
        const tableData = this.getTableData();
        const { tableConfig, displayWage } = this.props;

        const counts = this.getCountsFromData(this.state.data);

        const studentCount = +counts.studentCount;

        const fee = +this.getValue(tableConfig.feeTag);
        const overHead =
            displayWage === 'state'
                ? +this.getValue(tableConfig.stateOverheadTag)
                : +this.getValue(tableConfig.nationalOverheadTag);

        const feePerStudent = studentCount ? fee / studentCount : 0;

        const overHeadPerStudent = studentCount ? overHead / studentCount : 0;

        let studentTotal = feePerStudent + overHeadPerStudent;

        let yearTotal = overHead + fee;

        let studentCost = 0;
        let yearCost = 0;

        return (
            <tbody>
                {tableData.map((row, i) => {
                    if (i !== 4) {
                        studentCost = this.getValue(row[1]);
                        yearCost = this.getValue(row[2]);

                        if (studentCost) {
                            studentTotal += studentCost;
                        }
                        if (yearCost) {
                            yearTotal += yearCost;
                        }
                    }

                    if (i === 4) {
                        return (
                            <tr key={i} className={`${style.tableRow} ${style.totalRow}`}>
                                <td>{row[0]}</td>
                                <td className={style.numberCell}>
                                    {studentTotal === 0
                                        ? null
                                        : '$' + this.numberWithCommas(studentTotal)}
                                </td>
                                <td className={style.numberCell}>
                                    {yearTotal === 0
                                        ? null
                                        : '$' + this.numberWithCommas(yearTotal)}
                                </td>
                            </tr>
                        );
                    }

                    return (
                        <tr key={i} className={`${style.tableRow}`}>
                            <td>{row[0]}</td>
                            <td className={style.numberCell}>
                                {studentCost ? '$' + this.numberWithCommas(studentCost) : null}
                            </td>
                            <td className={style.numberCell}>
                                {yearCost ? '$' + this.numberWithCommas(yearCost) : null}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        );
    };

    renderInput = type => {
        const input = this.state.studentInput;
        return (
            <>
                <BrowserOnly>
                    <input
                        type="text"
                        className={style.studentInput}
                        value={input ? input : ''}
                        onChange={event => {
                            this.handleInputChange(event, 'student');
                        }}
                    />
                </BrowserOnly>
                <PrintOnly>{input}</PrintOnly>
            </>
        );
    };

    renderTableInfo = () => {
        const index = this.props.index;
        const data = this.state.data;
        const counts = this.getCountsFromData(data);
        const devTags = this.props.data.graphql.reportMetrics;
        const subTitle = devTags[this.props.tableConfig.subTitleTag];
        const title = this.props.settings[this.props.tableConfig.titleHandle];
        const initialValues = this.getInitialValues();
        const recalcValues = this.state.recalcData ? this.state.recalcData : null;

        let formValues;

        if (recalcValues && !this.state.hasFormInput) {
            formValues = recalcValues;
        } else if (this.state.hasFormInput) {
            formValues = this.props.currentFormInput;
        } else {
            formValues = initialValues;
        }

        return (
            <div
                className={`${this.state.open ? style.tableInfo : style.tableInfoClosed} ${
                    index === 0 ? style.firstInfo : style.otherInfo
                }`}
            >
                <div className={style.innerInfoWrapper}>
                    <div className={style.tableInfoLeft}>
                        <span className={style.totalText}>Total # Hours Coaching:</span>
                        <span className={style.inputBox}>{counts.hoursCount}</span>
                        {index !== 0 && (
                            <Modal
                                trigger={
                                    <button
                                        type="button"
                                        className={`btn btn-default ${
                                            this.state.hasFormInput
                                                ? style.editHoursFilled
                                                : style.editHours
                                        }`}
                                    >
                                        <span>
                                            <Pencil />
                                        </span>
                                    </button>
                                }
                                ref={this.formModal}
                                title={
                                    <h4>
                                        {title}
                                        {subTitle && <> - {subTitle}</>}
                                    </h4>
                                }
                            >
                                <h5>
                                    How many staff provide support for the program? How much time
                                    did/will each staff member spend providing support this year?
                                </h5>
                                <HoursForm
                                    onSubmit={this.onFormSubmit}
                                    initialValues={formValues}
                                    handle={this.props.tableConfig.handle}
                                    closeModal={this.closeModal}
                                />
                            </Modal>
                        )}
                    </div>
                    <div className={style.tableInfoRight}>
                        <span className={style.totalText}>
                            {index === 0
                                ? `Total # Students in School:`
                                : `Total # Students Participating:`}
                        </span>
                        <span className={style.inputBox}>
                            {index !== 0 ? this.renderInput('student') : counts.studentCount}
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return (
            <>
                {this.renderCardHeader()}
                {this.state.open && this.state.data && (
                    <>
                        {this.renderTableInfo()}
                        <table className={`table ${style.mainTable}`}>
                            {this.renderTableHead()}
                            {this.renderTableBody()}
                        </table>
                    </>
                )}
            </>
        );
    }
}

RenderTables.propTypes = {
    settings: PropTypes.object,
    data: PropTypes.object,
    tableConfig: PropTypes.object,
    index: PropTypes.number,
    openAll: PropTypes.bool,
    resetOpenAll: PropTypes.func,
    displayWage: PropTypes.string,
    saveCounts: PropTypes.func,
    onHoursFormSubmit: PropTypes.func,
    currentFormInput: PropTypes.object,
    currentStudents: PropTypes.object,
    recalcData: PropTypes.object,
    isRecalc: PropTypes.bool,
    studentCountTags: PropTypes.array,
};

RenderTables.defaultProps = {};

export default RenderTables;
