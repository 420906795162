import React from 'react';
import PropTypes from 'prop-types';
import { Report, Page, BrowserOnly, PrintOnly } from 'cccisd-laravel-report';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { client } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import Loader from 'cccisd-loader';
import Tooltip from 'cccisd-tooltip';
import Download from 'cccisd-icons/download8';
import Plus from 'cccisd-icons/plus-circle2';
import Info from 'cccisd-icons/info2';
import Rotate from 'cccisd-icons/rotate-ccw3';
import Calculator from 'cccisd-icons/calculator2';
import Pencil from 'cccisd-icons/pencil7';
import RenderTables from './RenderTables';
import Select from './Select';
import style from './style.css';
import metricsQuery from './metrics.graphql';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import Notification from 'cccisd-notification';
import { Html } from 'cccisd-wysiwyg';

const RNGLarge = () => {
    return Math.round(Math.floor(Math.random() * 5000));
};
const RNGMedium = () => {
    return Math.round(Math.floor(Math.random() * 1000));
};
const RNGSmall = () => {
    return Math.round(Math.floor(Math.random() * 500));
};

// Replace with data from graphql
const fakeGraphQLData = {
    assignmentProgress: {
        completedDate: '03/20/2021',

        reportMetrics: {
            SchoolName: 'Some School Name',

            PBISSelected: '1',
            SELPSelected: '1',
            BPPSelected: '1',
            OtherTier1Selected: '1',
            Tier2Selected: '1',
            Tier3Selected: '1',
            SDPSelected: '1',
            OtherTypeSelected: '1',

            AddSELPSelected: '1',
            AddBPPSelected: '1',
            AddOtherTier1Selected: '1',
            AddTier2Selected: '1',
            AddTier3Selected: '1',
            AddSDPSelected: '1',
            AddOtherTypeSelected: '1',

            PBISName: 'PBIS',
            SELPName1: 'Social Emotional',
            BPPName1: 'BPN',
            OtherTier1Name1: 'Other Tier 1',
            Tier2Name1: 'Tier 2 Name',
            Tier3Name1: 'Tier 3 Name',
            SDPName1: 'Mentoring',
            OtherTypeName1: 'Other Name',

            SELPName2: 'Social Emotional -- 2',
            BPPName2: 'BPN -- 2',
            OtherTier1Name2: 'Other Tier 1 -- 2',
            Tier2Name2: 'Tier 2 Name -- 2',
            Tier3Name2: 'Tier 3 Name -- 2',
            SDPName2: 'Mentoring -- 2',
            OtherTypeName2: 'Other Name -- 2',

            SELPName3: 'Social Emotional -- 3',
            BPPName3: 'BPN -- 3',
            OtherTier1Name3: 'Other Tier 1 -- 3',
            Tier2Name3: 'Tier 2 Name -- 3',
            Tier3Name3: 'Tier 3 Name -- 3',
            SDPName3: 'Mentoring -- 3',
            OtherTypeName3: 'Other Name -- 3',

            // Old Fake Data
            // TrainingCostStudent: 1, // PH
            // TrainingCostYear: 5, // PH
            // StaffInterventionCostStudent: 3, // PH
            // StaffInterventionCostYear: 15, // PH
            // VolTimeCostStudent: 4, // PH
            // VolTimeCostYear: 20, // PH
            // TotalHours: 100, // PH
            // studentCount: 23, // PH

            NumStudentsPBIS: RNGSmall(),
            NumStudentsSEL1: RNGSmall(),
            NumStudentsSEL2: RNGSmall(),
            NumStudentsSEL3: RNGSmall(),
            NumStudentsBUL1: RNGSmall(),
            NumStudentsBUL2: RNGSmall(),
            NumStudentsBUL3: RNGSmall(),
            NumStudentsT1_1: RNGSmall(),
            NumStudentsT1_2: RNGSmall(),
            NumStudentsT1_3: RNGSmall(),
            NumStudentsT2_1: RNGSmall(),
            NumStudentsT2_2: RNGSmall(),
            NumStudentsT2_3: RNGSmall(),
            NumStudentsT3_1: RNGSmall(),
            NumStudentsT3_2: RNGSmall(),
            NumStudentsT3_3: RNGSmall(),
            NumStudentsSD1: RNGSmall(),
            NumStudentsSD2: RNGSmall(),
            NumStudentsSD3: RNGSmall(),
            NumStudentsOP1: RNGSmall(),
            NumStudentsOP2: RNGSmall(),
            NumStudentsOP3: RNGSmall(),
            NumStudentsTotal: RNGSmall(),
        },
        initialValues: {
            SchoolName: 'Some School Name',

            PBISSelected: '1',
            SELPSelected: '1',
            BPPSelected: '1',
            OtherTier1Selected: '1',
            Tier2Selected: '1',
            Tier3Selected: '1',
            SDPSelected: '1',
            OtherTypeSelected: '1',

            AddSELPSelected: '1',
            AddBPPSelected: '1',
            AddOtherTier1Selected: '1',
            AddTier2Selected: '1',
            AddTier3Selected: '1',
            AddSDPSelected: '1',
            AddOtherTypeSelected: '1',

            PBISName: 'PBIS',
            SELPName1: 'Social Emotional',
            BPPName1: 'BPN',
            OtherTier1Name1: 'Other Tier 1',
            Tier2Name1: 'Tier 2 Name',
            Tier3Name1: 'Tier 3 Name',
            SDPName1: 'Mentoring',
            OtherTypeName1: 'Other Name',

            SELPName2: 'Social Emotional -- 2',
            BPPName2: 'BPN -- 2',
            OtherTier1Name2: 'Other Tier 1 -- 2',
            Tier2Name2: 'Tier 2 Name -- 2',
            Tier3Name2: 'Tier 3 Name -- 2',
            SDPName2: 'Mentoring -- 2',
            OtherTypeName2: 'Other Name -- 2',

            SELPName3: 'Social Emotional -- 3',
            BPPName3: 'BPN -- 3',
            OtherTier1Name3: 'Other Tier 1 -- 3',
            Tier2Name3: 'Tier 2 Name -- 3',
            Tier3Name3: 'Tier 3 Name -- 3',
            SDPName3: 'Mentoring -- 3',
            OtherTypeName3: 'Other Name -- 3',

            // Old Fake Data
            // TrainingCostStudent: 1, // PH
            // TrainingCostYear: 5, // PH
            // StaffInterventionCostStudent: 3, // PH
            // StaffInterventionCostYear: 15, // PH
            // VolTimeCostStudent: 4, // PH
            // VolTimeCostYear: 20, // PH
            // TotalHours: 100, // PH
            // studentCount: 23, // PH

            NumStudentsPBIS: RNGSmall(),
            NumStudentsSEL1: RNGSmall(),
            NumStudentsSEL2: RNGSmall(),
            NumStudentsSEL3: RNGSmall(),
            NumStudentsBUL1: RNGSmall(),
            NumStudentsBUL2: RNGSmall(),
            NumStudentsBUL3: RNGSmall(),
            NumStudentsT1_1: RNGSmall(),
            NumStudentsT1_2: RNGSmall(),
            NumStudentsT1_3: RNGSmall(),
            NumStudentsT2_1: RNGSmall(),
            NumStudentsT2_2: RNGSmall(),
            NumStudentsT2_3: RNGSmall(),
            NumStudentsT3_1: RNGSmall(),
            NumStudentsT3_2: RNGSmall(),
            NumStudentsT3_3: RNGSmall(),
            NumStudentsSD1: RNGSmall(),
            NumStudentsSD2: RNGSmall(),
            NumStudentsSD3: RNGSmall(),
            NumStudentsOP1: RNGSmall(),
            NumStudentsOP2: RNGSmall(),
            NumStudentsOP3: RNGSmall(),
            NumStudentsTotal: RNGSmall(),
        },
    },
};

const fakeScoringData = {
    // PH
    totals: {
        trainingPerStudentState: RNGMedium(),
        trainingState: RNGLarge(),
        staffPerStudentState: RNGMedium(),
        staffState: RNGLarge(),
        trainingPerStudentNational: RNGMedium(),
        trainingNational: RNGLarge(),
        staffPerStudentNational: RNGMedium(),
        staffNational: RNGLarge(),
        volunteersPerStudent: RNGMedium(),
        volunteers: RNGMedium(),
        coachingHours: RNGSmall(),
    },
    // ___________________________
    PBIS: {
        TrainingPerStudentStatePBIS: RNGMedium(),
        TrainingTotalStatePBIS: RNGLarge(),
        StaffPerStudentStatePBIS: RNGMedium(),
        StaffTotalStatePBIS: RNGLarge(),
        TrainingPerStudentNationalPBIS: RNGMedium(),
        TrainingTotalNationalPBIS: RNGLarge(),
        StaffPerStudentNationalPBIS: RNGMedium(),
        StaffTotalNationalPBIS: RNGLarge(),
        VolunteersPerStudentPBIS: RNGMedium(),
        VolunteerMaterialsTotalPBIS: RNGMedium(),
        CoachingHoursPBIS: RNGSmall(),
    },
    SEL1: {
        TrainingPerStudentStateSEL1: RNGMedium(),
        TrainingTotalStateSEL1: RNGLarge(),
        StaffPerStudentStateSEL1: RNGMedium(),
        StaffTotalStateSEL1: RNGLarge(),
        TrainingPerStudentNationalSEL1: RNGMedium(),
        TrainingTotalNationalSEL1: RNGLarge(),
        StaffPerStudentNationalSEL1: RNGMedium(),
        StaffTotalNationalSEL1: RNGLarge(),
        VolunteersPerStudentSEL1: RNGMedium(),
        VolunteerMaterialsTotalSEL1: RNGMedium(),
        CoachingHoursSEL1: RNGSmall(),
    },
    SEL2: {
        TrainingPerStudentStateSEL2: RNGMedium(),
        TrainingTotalStateSEL2: RNGLarge(),
        StaffPerStudentStateSEL2: RNGMedium(),
        StaffTotalStateSEL2: RNGLarge(),
        TrainingPerStudentNationalSEL2: RNGMedium(),
        TrainingTotalNationalSEL2: RNGLarge(),
        StaffPerStudentNationalSEL2: RNGMedium(),
        StaffTotalNationalSEL2: RNGLarge(),
        VolunteersPerStudentSEL2: RNGMedium(),
        VolunteerMaterialsTotalSEL2: RNGMedium(),
        CoachingHoursSEL2: RNGSmall(),
    },
    SEL3: {
        TrainingPerStudentStateSEL3: RNGMedium(),
        TrainingTotalStateSEL3: RNGLarge(),
        StaffPerStudentStateSEL3: RNGMedium(),
        StaffTotalStateSEL3: RNGLarge(),
        TrainingPerStudentNationalSEL3: RNGMedium(),
        TrainingTotalNationalSEL3: RNGLarge(),
        StaffPerStudentNationalSEL3: RNGMedium(),
        StaffTotalNationalSEL3: RNGLarge(),
        VolunteersPerStudentSEL3: RNGMedium(),
        VolunteerMaterialsTotalSEL3: RNGMedium(),
        CoachingHoursSEL3: RNGSmall(),
    },
    BUL1: {
        TrainingPerStudentStateBUL1: RNGMedium(),
        TrainingTotalStateBUL1: RNGLarge(),
        StaffPerStudentStateBUL1: RNGMedium(),
        StaffTotalStateBUL1: RNGLarge(),
        TrainingPerStudentNationalBUL1: RNGMedium(),
        TrainingTotalNationalBUL1: RNGLarge(),
        StaffPerStudentNationalBUL1: RNGMedium(),
        StaffTotalNationalBUL1: RNGLarge(),
        VolunteersPerStudentBUL1: RNGMedium(),
        VolunteerMaterialsTotalBUL1: RNGMedium(),
        CoachingHoursBUL1: RNGSmall(),
    },
    BUL2: {
        TrainingPerStudentStateBUL2: RNGMedium(),
        TrainingTotalStateBUL2: RNGLarge(),
        StaffPerStudentStateBUL2: RNGMedium(),
        StaffTotalStateBUL2: RNGLarge(),
        TrainingPerStudentNationalBUL2: RNGMedium(),
        TrainingTotalNationalBUL2: RNGLarge(),
        StaffPerStudentNationalBUL2: RNGMedium(),
        StaffTotalNationalBUL2: RNGLarge(),
        VolunteersPerStudentBUL2: RNGMedium(),
        VolunteerMaterialsTotalBUL2: RNGMedium(),
        CoachingHoursBUL2: RNGSmall(),
    },
    BUL3: {
        TrainingPerStudentStateBUL3: RNGMedium(),
        TrainingTotalStateBUL3: RNGLarge(),
        StaffPerStudentStateBUL3: RNGMedium(),
        StaffTotalStateBUL3: RNGLarge(),
        TrainingPerStudentNationalBUL3: RNGMedium(),
        TrainingTotalNationalBUL3: RNGLarge(),
        StaffPerStudentNationalBUL3: RNGMedium(),
        StaffTotalNationalBUL3: RNGLarge(),
        VolunteersPerStudentBUL3: RNGMedium(),
        VolunteerMaterialsTotalBUL3: RNGMedium(),
        CoachingHoursBUL3: RNGSmall(),
    },
    T1_1: {
        TrainingPerStudentStateT1_1: RNGMedium(),
        TrainingTotalStateT1_1: RNGLarge(),
        StaffPerStudentStateT1_1: RNGMedium(),
        StaffTotalStateT1_1: RNGLarge(),
        TrainingPerStudentNationalT1_1: RNGMedium(),
        TrainingTotalNationalT1_1: RNGLarge(),
        StaffPerStudentNationalT1_1: RNGMedium(),
        StaffTotalNationalT1_1: RNGLarge(),
        VolunteersPerStudentT1_1: RNGMedium(),
        VolunteerMaterialsTotalT1_1: RNGMedium(),
        CoachingHoursT1_1: RNGSmall(),
    },
    T1_2: {
        TrainingPerStudentStateT1_2: RNGMedium(),
        TrainingTotalStateT1_2: RNGLarge(),
        StaffPerStudentStateT1_2: RNGMedium(),
        StaffTotalStateT1_2: RNGLarge(),
        TrainingPerStudentNationalT1_2: RNGMedium(),
        TrainingTotalNationalT1_2: RNGLarge(),
        StaffPerStudentNationalT1_2: RNGMedium(),
        StaffTotalNationalT1_2: RNGLarge(),
        VolunteersPerStudentT1_2: RNGMedium(),
        VolunteerMaterialsTotalT1_2: RNGMedium(),
        CoachingHoursT1_2: RNGSmall(),
    },
    T1_3: {
        TrainingPerStudentStateT1_3: RNGMedium(),
        TrainingTotalStateT1_3: RNGLarge(),
        StaffPerStudentStateT1_3: RNGMedium(),
        StaffTotalStateT1_3: RNGLarge(),
        TrainingPerStudentNationalT1_3: RNGMedium(),
        TrainingTotalNationalT1_3: RNGLarge(),
        StaffPerStudentNationalT1_3: RNGMedium(),
        StaffTotalNationalT1_3: RNGLarge(),
        VolunteersPerStudentT1_3: RNGMedium(),
        VolunteerMaterialsTotalT1_3: RNGMedium(),
        CoachingHoursT1_3: RNGSmall(),
    },
    T2_1: {
        TrainingPerStudentStateT2_1: RNGMedium(),
        TrainingTotalStateT2_1: RNGLarge(),
        StaffPerStudentStateT2_1: RNGMedium(),
        StaffTotalStateT2_1: RNGLarge(),
        TrainingPerStudentNationalT2_1: RNGMedium(),
        TrainingTotalNationalT2_1: RNGLarge(),
        StaffPerStudentNationalT2_1: RNGMedium(),
        StaffTotalNationalT2_1: RNGLarge(),
        VolunteersPerStudentT2_1: RNGMedium(),
        VolunteerMaterialsTotalT2_1: RNGMedium(),
        CoachingHoursT2_1: RNGSmall(),
    },
    T2_2: {
        TrainingPerStudentStateT2_2: RNGMedium(),
        TrainingTotalStateT2_2: RNGLarge(),
        StaffPerStudentStateT2_2: RNGMedium(),
        StaffTotalStateT2_2: RNGLarge(),
        TrainingPerStudentNationalT2_2: RNGMedium(),
        TrainingTotalNationalT2_2: RNGLarge(),
        StaffPerStudentNationalT2_2: RNGMedium(),
        StaffTotalNationalT2_2: RNGLarge(),
        VolunteersPerStudentT2_2: RNGMedium(),
        VolunteerMaterialsTotalT2_2: RNGMedium(),
        CoachingHoursT2_2: RNGSmall(),
    },
    T2_3: {
        TrainingPerStudentStateT2_3: RNGMedium(),
        TrainingTotalStateT2_3: RNGLarge(),
        StaffPerStudentStateT2_3: RNGMedium(),
        StaffTotalStateT2_3: RNGLarge(),
        TrainingPerStudentNationalT2_3: RNGMedium(),
        TrainingTotalNationalT2_3: RNGLarge(),
        StaffPerStudentNationalT2_3: RNGMedium(),
        StaffTotalNationalT2_3: RNGLarge(),
        VolunteersPerStudentT2_3: RNGMedium(),
        VolunteerMaterialsTotalT2_3: RNGMedium(),
        CoachingHoursT2_3: RNGSmall(),
    },
    T3_1: {
        TrainingPerStudentStateT3_1: RNGMedium(),
        TrainingTotalStateT3_1: RNGLarge(),
        StaffPerStudentStateT3_1: RNGMedium(),
        StaffTotalStateT3_1: RNGLarge(),
        TrainingPerStudentNationalT3_1: RNGMedium(),
        TrainingTotalNationalT3_1: RNGLarge(),
        StaffPerStudentNationalT3_1: RNGMedium(),
        StaffTotalNationalT3_1: RNGLarge(),
        VolunteersPerStudentT3_1: RNGMedium(),
        VolunteerMaterialsTotalT3_1: RNGMedium(),
        CoachingHoursT3_1: RNGSmall(),
    },
    T3_2: {
        TrainingPerStudentStateT3_2: RNGMedium(),
        TrainingTotalStateT3_2: RNGLarge(),
        StaffPerStudentStateT3_2: RNGMedium(),
        StaffTotalStateT3_2: RNGLarge(),
        TrainingPerStudentNationalT3_2: RNGMedium(),
        TrainingTotalNationalT3_2: RNGLarge(),
        StaffPerStudentNationalT3_2: RNGMedium(),
        StaffTotalNationalT3_2: RNGLarge(),
        VolunteersPerStudentT3_2: RNGMedium(),
        VolunteerMaterialsTotalT3_2: RNGMedium(),
        CoachingHoursT3_2: RNGSmall(),
    },
    T3_3: {
        TrainingPerStudentStateT3_3: RNGMedium(),
        TrainingTotalStateT3_3: RNGLarge(),
        StaffPerStudentStateT3_3: RNGMedium(),
        StaffTotalStateT3_3: RNGLarge(),
        TrainingPerStudentNationalT3_3: RNGMedium(),
        TrainingTotalNationalT3_3: RNGLarge(),
        StaffPerStudentNationalT3_3: RNGMedium(),
        StaffTotalNationalT3_3: RNGLarge(),
        VolunteersPerStudentT3_3: RNGMedium(),
        VolunteerMaterialsTotalT3_3: RNGMedium(),
        CoachingHoursT3_3: RNGSmall(),
    },
    SD1: {
        TrainingPerStudentStateSD1: RNGMedium(),
        TrainingTotalStateSD1: RNGLarge(),
        StaffPerStudentStateSD1: RNGMedium(),
        StaffTotalStateSD1: RNGLarge(),
        TrainingPerStudentNationalSD1: RNGMedium(),
        TrainingTotalNationalSD1: RNGLarge(),
        StaffPerStudentNationalSD1: RNGMedium(),
        StaffTotalNationalSD1: RNGLarge(),
        VolunteersPerStudentSD1: RNGMedium(),
        VolunteerMaterialsTotalSD1: RNGMedium(),
        CoachingHoursSD1: RNGSmall(),
    },
    SD2: {
        TrainingPerStudentStateSD2: RNGMedium(),
        TrainingTotalStateSD2: RNGLarge(),
        StaffPerStudentStateSD2: RNGMedium(),
        StaffTotalStateSD2: RNGLarge(),
        TrainingPerStudentNationalSD2: RNGMedium(),
        TrainingTotalNationalSD2: RNGLarge(),
        StaffPerStudentNationalSD2: RNGMedium(),
        StaffTotalNationalSD2: RNGLarge(),
        VolunteersPerStudentSD2: RNGMedium(),
        VolunteerMaterialsTotalSD2: RNGMedium(),
        CoachingHoursSD2: RNGSmall(),
    },
    SD3: {
        TrainingPerStudentStateSD3: RNGMedium(),
        TrainingTotalStateSD3: RNGLarge(),
        StaffPerStudentStateSD3: RNGMedium(),
        StaffTotalStateSD3: RNGLarge(),
        TrainingPerStudentNationalSD3: RNGMedium(),
        TrainingTotalNationalSD3: RNGLarge(),
        StaffPerStudentNationalSD3: RNGMedium(),
        StaffTotalNationalSD3: RNGLarge(),
        VolunteersPerStudentSD3: RNGMedium(),
        VolunteerMaterialsTotalSD3: RNGMedium(),
        CoachingHoursSD3: RNGSmall(),
    },
    OP1: {
        TrainingPerStudentStateOP1: RNGMedium(),
        TrainingTotalStateOP1: RNGLarge(),
        StaffPerStudentStateOP1: RNGMedium(),
        StaffTotalStateOP1: RNGLarge(),
        TrainingPerStudentNationalOP1: RNGMedium(),
        TrainingTotalNationalOP1: RNGLarge(),
        StaffPerStudentNationalOP1: RNGMedium(),
        StaffTotalNationalOP1: RNGLarge(),
        VolunteersPerStudentOP1: RNGMedium(),
        VolunteerMaterialsTotalOP1: RNGMedium(),
        CoachingHoursOP1: RNGSmall(),
    },
    OP2: {
        TrainingPerStudentStateOP2: RNGMedium(),
        TrainingTotalStateOP2: RNGLarge(),
        StaffPerStudentStateOP2: RNGMedium(),
        StaffTotalStateOP2: RNGLarge(),
        TrainingPerStudentNationalOP2: RNGMedium(),
        TrainingTotalNationalOP2: RNGLarge(),
        StaffPerStudentNationalOP2: RNGMedium(),
        StaffTotalNationalOP2: RNGLarge(),
        VolunteersPerStudentOP2: RNGMedium(),
        VolunteerMaterialsTotalOP2: RNGMedium(),
        CoachingHoursOP2: RNGSmall(),
    },
    OP3: {
        TrainingPerStudentStateOP3: RNGMedium(),
        TrainingTotalStateOP3: RNGLarge(),
        StaffPerStudentStateOP3: RNGMedium(),
        StaffTotalStateOP3: RNGLarge(),
        TrainingPerStudentNationalOP3: RNGMedium(),
        TrainingTotalNationalOP3: RNGLarge(),
        StaffPerStudentNationalOP3: RNGMedium(),
        StaffTotalNationalOP3: RNGLarge(),
        VolunteersPerStudentOP3: RNGMedium(),
        VolunteerMaterialsTotalOP3: RNGMedium(),
        CoachingHoursOP3: RNGSmall(),
    },
};

const Tables = [
    {
        handle: 'totals', // handle is data path
        label: 'Total Cost of All Interventions',
        stateValues: [
            'trainingPerStudentState',
            'trainingState',
            'staffPerStudentState',
            'staffState',
            'volunteersPerStudent',
            'volunteers',
            'parentsPerStudent',
            'parents',
        ],
        nationalValues: [
            'trainingPerStudentNational',
            'trainingNational',
            'staffPerStudentNational',
            'staffNational',
            'volunteersPerStudent',
            'volunteers',
            'parentsPerStudent',
            'parents',
        ],
        feeTag: 'fee',
        stateOverheadTag: 'overheadState',
        nationalOverheadTag: 'overheadNational',
        titleHandle: 'mainTableTitle',
        studentCountTag: 'NumStudentsTotal',
        hoursCountTag: 'coachingHours',
        condition: () => {
            return true;
        },
    },
    {
        handle: 'PBIS',
        label: 'Positive Behavioral Interventions and Supports',
        stateValues: [
            'TrainingPerStudentStatePBIS',
            'TrainingTotalStatePBIS',
            'StaffPerStudentStatePBIS',
            'StaffTotalStatePBIS',
            'VolunteersPerStudentPBIS',
            'VolunteerMaterialsTotalPBIS',
            'ParentsPerStudentPBIS',
            'ParentsTotalPBIS',
        ],
        nationalValues: [
            'TrainingPerStudentNationalPBIS',
            'TrainingTotalNationalPBIS',
            'StaffPerStudentNationalPBIS',
            'StaffTotalNationalPBIS',
            'VolunteersPerStudentPBIS',
            'VolunteerMaterialsTotalPBIS',
            'ParentsPerStudentPBIS',
            'ParentsTotalPBIS',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportPBIS',
            HoursAdmins: 'HoursAdminsSupportPBIS',
            NumSMHP: 'NumSMHPSupportPBIS',
            HoursSMHP: 'HoursSMHPSupportPBIS',
            NumTeachers: 'NumTeachersSupportPBIS',
            HoursTeachers: 'HoursTeachersSupportPBIS',
            NumOthers: 'NumOthersSupportPBIS',
            HoursOthers: 'HoursOthersSupportPBIS',
        },
        feeTag: 'FeePBIS',
        stateOverheadTag: 'OverheadStatePBIS',
        nationalOverheadTag: 'OverheadNationalPBIS',
        titleHandle: 'PBISTableTitle',
        subTitleTag: 'PBISName',
        studentCountTag: 'NumStudentsPBIS',
        hoursCountTag: 'CoachingHoursPBIS',
        condition: devTags => {
            return +devTags.PBISSelected === 1;
        },
    },
    {
        handle: 'SEL1',
        label: 'Social Emotional Learning Program',
        stateValues: [
            'TrainingPerStudentStateSEL1',
            'TrainingTotalStateSEL1',
            'StaffPerStudentStateSEL1',
            'StaffTotalStateSEL1',
            'VolunteersPerStudentSEL1',
            'VolunteerMaterialsTotalSEL1',
            'ParentsPerStudentSEL1',
            'ParentsTotalSEL1',
        ],
        nationalValues: [
            'TrainingPerStudentNationalSEL1',
            'TrainingTotalNationalSEL1',
            'StaffPerStudentNationalSEL1',
            'StaffTotalNationalSEL1',
            'VolunteersPerStudentSEL1',
            'VolunteerMaterialsTotalSEL1',
            'ParentsPerStudentSEL1',
            'ParentsTotalSEL1',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportSEL1',
            HoursAdmins: 'HoursAdminsSupportSEL1',
            NumSMHP: 'NumSMHPSupportSEL1',
            HoursSMHP: 'HoursSMHPSupportSEL1',
            NumTeachers: 'NumTeachersSupportSEL1',
            HoursTeachers: 'HoursTeachersSupportSEL1',
            NumOthers: 'NumOthersSupportSEL1',
            HoursOthers: 'HoursOthersSupportSEL1',
        },
        feeTag: 'FeeSEL1',
        stateOverheadTag: 'OverheadStateSEL1',
        nationalOverheadTag: 'OverheadNationalSEL1',
        titleHandle: 'SELPTableTitle',
        subTitleTag: 'SELPName1',
        studentCountTag: 'NumStudentsSEL1',
        hoursCountTag: 'CoachingHoursSEL1',
        condition: devTags => {
            return +devTags.SELPSelected === 1;
        },
    },
    {
        handle: 'SEL2',
        label: 'Social Emotional Learning Program',
        stateValues: [
            'TrainingPerStudentStateSEL2',
            'TrainingTotalStateSEL2',
            'StaffPerStudentStateSEL2',
            'StaffTotalStateSEL2',
            'VolunteersPerStudentSEL2',
            'VolunteerMaterialsTotalSEL2',
            'ParentsPerStudentSEL2',
            'ParentsTotalSEL2',
        ],
        nationalValues: [
            'TrainingPerStudentNationalSEL2',
            'TrainingTotalNationalSEL2',
            'StaffPerStudentNationalSEL2',
            'StaffTotalNationalSEL2',
            'VolunteersPerStudentSEL2',
            'VolunteerMaterialsTotalSEL2',
            'ParentsPerStudentSEL2',
            'ParentsTotalSEL2',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportSEL2',
            HoursAdmins: 'HoursAdminsSupportSEL2',
            NumSMHP: 'NumSMHPSupportSEL2',
            HoursSMHP: 'HoursSMHPSupportSEL2',
            NumTeachers: 'NumTeachersSupportSEL2',
            HoursTeachers: 'HoursTeachersSupportSEL2',
            NumOthers: 'NumOthersSupportSEL2',
            HoursOthers: 'HoursOthersSupportSEL2',
        },
        feeTag: 'FeeSEL2',
        stateOverheadTag: 'OverheadStateSEL2',
        nationalOverheadTag: 'OverheadNationalSEL2',
        titleHandle: 'SELPTableTitle',
        subTitleTag: 'SELPName2',
        studentCountTag: 'NumStudentsSEL2',
        hoursCountTag: 'CoachingHoursSEL2',
        condition: devTags => {
            return +devTags.AddSELPSelected === 1 && devTags.SELPName2 && devTags.SELPName2 !== '';
        },
    },
    {
        handle: 'SEL3',
        label: 'Social Emotional Learning Program',
        stateValues: [
            'TrainingPerStudentStateSEL3',
            'TrainingTotalStateSEL3',
            'StaffPerStudentStateSEL3',
            'StaffTotalStateSEL3',
            'VolunteersPerStudentSEL3',
            'VolunteerMaterialsTotalSEL3',
            'ParentsPerStudentSEL3',
            'ParentsTotalSEL3',
        ],
        nationalValues: [
            'TrainingPerStudentNationalSEL3',
            'TrainingTotalNationalSEL3',
            'StaffPerStudentNationalSEL3',
            'StaffTotalNationalSEL3',
            'VolunteersPerStudentSEL3',
            'VolunteerMaterialsTotalSEL3',
            'ParentsPerStudentSEL3',
            'ParentsTotalSEL3',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportSEL3',
            HoursAdmins: 'HoursAdminsSupportSEL3',
            NumSMHP: 'NumSMHPSupportSEL3',
            HoursSMHP: 'HoursSMHPSupportSEL3',
            NumTeachers: 'NumTeachersSupportSEL3',
            HoursTeachers: 'HoursTeachersSupportSEL3',
            NumOthers: 'NumOthersSupportSEL3',
            HoursOthers: 'HoursOthersSupportSEL3',
        },
        feeTag: 'FeeSEL3',
        stateOverheadTag: 'OverheadStateSEL3',
        nationalOverheadTag: 'OverheadNationalSEL3',
        titleHandle: 'SELPTableTitle',
        subTitleTag: 'SELPName3',
        studentCountTag: 'NumStudentsSEL3',
        hoursCountTag: 'CoachingHoursSEL3',
        condition: devTags => {
            return +devTags.AddSELPSelected === 1 && devTags.SELPName3 && devTags.SELPName3 !== '';
        },
    },
    {
        handle: 'BUL1',
        label: 'Bullying Prevention Program',
        stateValues: [
            'TrainingPerStudentStateBUL1',
            'TrainingTotalStateBUL1',
            'StaffPerStudentStateBUL1',
            'StaffTotalStateBUL1',
            'VolunteersPerStudentBUL1',
            'VolunteerMaterialsTotalBUL1',
            'ParentsPerStudentBUL1',
            'ParentsTotalBUL1',
        ],
        nationalValues: [
            'TrainingPerStudentNationalBUL1',
            'TrainingTotalNationalBUL1',
            'StaffPerStudentNationalBUL1',
            'StaffTotalNationalBUL1',
            'VolunteersPerStudentBUL1',
            'VolunteerMaterialsTotalBUL1',
            'ParentsPerStudentBUL1',
            'ParentsTotalBUL1',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportBUL1',
            HoursAdmins: 'HoursAdminsSupportBUL1',
            NumSMHP: 'NumSMHPSupportBUL1',
            HoursSMHP: 'HoursSMHPSupportBUL1',
            NumTeachers: 'NumTeachersSupportBUL1',
            HoursTeachers: 'HoursTeachersSupportBUL1',
            NumOthers: 'NumOthersSupportBUL1',
            HoursOthers: 'HoursOthersSupportBUL1',
        },
        feeTag: 'FeeBUL1',
        stateOverheadTag: 'OverheadStateBUL1',
        nationalOverheadTag: 'OverheadNationalBUL1',
        titleHandle: 'BPPTableTitle',
        subTitleTag: 'BPPName1',
        studentCountTag: 'NumStudentsBUL1',
        hoursCountTag: 'CoachingHoursBUL1',
        condition: devTags => {
            return +devTags.BPPSelected === 1;
        },
    },
    {
        handle: 'BUL2',
        label: 'Bullying Prevention Program',
        stateValues: [
            'TrainingPerStudentStateBUL2',
            'TrainingTotalStateBUL2',
            'StaffPerStudentStateBUL2',
            'StaffTotalStateBUL2',
            'VolunteersPerStudentBUL2',
            'VolunteerMaterialsTotalBUL2',
            'ParentsPerStudentBUL2',
            'ParentsTotalBUL2',
        ],
        nationalValues: [
            'TrainingPerStudentNationalBUL2',
            'TrainingTotalNationalBUL2',
            'StaffPerStudentNationalBUL2',
            'StaffTotalNationalBUL2',
            'VolunteersPerStudentBUL2',
            'VolunteerMaterialsTotalBUL2',
            'ParentsPerStudentBUL2',
            'ParentsTotalBUL2',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportBUL2',
            HoursAdmins: 'HoursAdminsSupportBUL2',
            NumSMHP: 'NumSMHPSupportBUL2',
            HoursSMHP: 'HoursSMHPSupportBUL2',
            NumTeachers: 'NumTeachersSupportBUL2',
            HoursTeachers: 'HoursTeachersSupportBUL2',
            NumOthers: 'NumOthersSupportBUL2',
            HoursOthers: 'HoursOthersSupportBUL2',
        },
        feeTag: 'FeeBUL2',
        stateOverheadTag: 'OverheadStateBUL2',
        nationalOverheadTag: 'OverheadNationalBUL2',
        titleHandle: 'BPPTableTitle',
        subTitleTag: 'BPPName2',
        studentCountTag: 'NumStudentsBUL2',
        hoursCountTag: 'CoachingHoursBUL2',
        condition: devTags => {
            return +devTags.AddBPPSelected === 1 && devTags.BPPName2 && devTags.BPPName2 !== '';
        },
    },
    {
        handle: 'BUL3',
        label: 'Bullying Prevention Program',
        stateValues: [
            'TrainingPerStudentStateBUL3',
            'TrainingTotalStateBUL3',
            'StaffPerStudentStateBUL3',
            'StaffTotalStateBUL3',
            'VolunteersPerStudentBUL3',
            'VolunteerMaterialsTotalBUL3',
            'ParentsPerStudentBUL3',
            'ParentsTotalBUL3',
        ],
        nationalValues: [
            'TrainingPerStudentNationalBUL3',
            'TrainingTotalNationalBUL3',
            'StaffPerStudentNationalBUL3',
            'StaffTotalNationalBUL3',
            'VolunteersPerStudentBUL3',
            'VolunteerMaterialsTotalBUL3',
            'ParentsPerStudentBUL3',
            'ParentsTotalBUL3',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportBUL3',
            HoursAdmins: 'HoursAdminsSupportBUL3',
            NumSMHP: 'NumSMHPSupportBUL3',
            HoursSMHP: 'HoursSMHPSupportBUL3',
            NumTeachers: 'NumTeachersSupportBUL3',
            HoursTeachers: 'HoursTeachersSupportBUL3',
            NumOthers: 'NumOthersSupportBUL3',
            HoursOthers: 'HoursOthersSupportBUL3',
        },
        feeTag: 'FeeBUL3',
        stateOverheadTag: 'OverheadStateBUL3',
        nationalOverheadTag: 'OverheadNationalBUL3',
        titleHandle: 'BPPTableTitle',
        subTitleTag: 'BPPName3',
        studentCountTag: 'NumStudentsBUL3',
        hoursCountTag: 'CoachingHoursBUL3',
        condition: devTags => {
            return +devTags.AddBPPSelected === 1 && devTags.BPPName3 && devTags.BPPName3 !== '';
        },
    },
    {
        handle: 'T1_1',
        label: 'Other Tier 1 Program',
        stateValues: [
            'TrainingPerStudentStateT1_1',
            'TrainingTotalStateT1_1',
            'StaffPerStudentStateT1_1',
            'StaffTotalStateT1_1',
            'VolunteersPerStudentT1_1',
            'VolunteerMaterialsTotalT1_1',
            'ParentsPerStudentT1_1',
            'ParentsTotalT1_1',
        ],
        nationalValues: [
            'TrainingPerStudentNationalT1_1',
            'TrainingTotalNationalT1_1',
            'StaffPerStudentNationalT1_1',
            'StaffTotalNationalT1_1',
            'VolunteersPerStudentT1_1',
            'VolunteerMaterialsTotalT1_1',
            'ParentsPerStudentT1_1',
            'ParentsTotalT1_1',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportT1_1',
            HoursAdmins: 'HoursAdminsSupportT1_1',
            NumSMHP: 'NumSMHPSupportT1_1',
            HoursSMHP: 'HoursSMHPSupportT1_1',
            NumTeachers: 'NumTeachersSupportT1_1',
            HoursTeachers: 'HoursTeachersSupportT1_1',
            NumOthers: 'NumOthersSupportT1_1',
            HoursOthers: 'HoursOthersSupportT1_1',
        },
        feeTag: 'FeeT1_1',
        stateOverheadTag: 'OverheadStateT1_1',
        nationalOverheadTag: 'OverheadNationalT1_1',
        titleHandle: 'OT1TableTitle',
        subTitleTag: 'OtherTier1Name1',
        studentCountTag: 'NumStudentsT1_1',
        hoursCountTag: 'CoachingHoursT1_1',
        condition: devTags => {
            return +devTags.OtherTier1Selected === 1;
        },
    },
    {
        handle: 'T1_2',
        label: 'Other Tier 1 Program',
        stateValues: [
            'TrainingPerStudentStateT1_2',
            'TrainingTotalStateT1_2',
            'StaffPerStudentStateT1_2',
            'StaffTotalStateT1_2',
            'VolunteersPerStudentT1_2',
            'VolunteerMaterialsTotalT1_2',
            'ParentsPerStudentT1_2',
            'ParentsTotalT1_2',
        ],
        nationalValues: [
            'TrainingPerStudentNationalT1_2',
            'TrainingTotalNationalT1_2',
            'StaffPerStudentNationalT1_2',
            'StaffTotalNationalT1_2',
            'VolunteersPerStudentT1_2',
            'VolunteerMaterialsTotalT1_2',
            'ParentsPerStudentT1_2',
            'ParentsTotalT1_2',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportT1_2',
            HoursAdmins: 'HoursAdminsSupportT1_2',
            NumSMHP: 'NumSMHPSupportT1_2',
            HoursSMHP: 'HoursSMHPSupportT1_2',
            NumTeachers: 'NumTeachersSupportT1_2',
            HoursTeachers: 'HoursTeachersSupportT1_2',
            NumOthers: 'NumOthersSupportT1_2',
            HoursOthers: 'HoursOthersSupportT1_2',
        },
        feeTag: 'FeeT1_2',
        stateOverheadTag: 'OverheadStateT1_2',
        nationalOverheadTag: 'OverheadNationalT1_2',
        titleHandle: 'OT1TableTitle',
        subTitleTag: 'OtherTier1Name2',
        studentCountTag: 'NumStudentsT1_2',
        hoursCountTag: 'CoachingHoursT1_2',
        condition: devTags => {
            return (
                +devTags.AddOtherTier1Selected === 1 &&
                devTags.OtherTier1Name2 &&
                devTags.OtherTier1Name2 !== ''
            );
        },
    },
    {
        handle: 'T1_3',
        label: 'Other Tier 1 Program',
        stateValues: [
            'TrainingPerStudentStateT1_3',
            'TrainingTotalStateT1_3',
            'StaffPerStudentStateT1_3',
            'StaffTotalStateT1_3',
            'VolunteersPerStudentT1_3',
            'VolunteerMaterialsTotalT1_3',
            'ParentsPerStudentT1_3',
            'ParentsTotalT1_3',
        ],
        nationalValues: [
            'TrainingPerStudentNationalT1_3',
            'TrainingTotalNationalT1_3',
            'StaffPerStudentNationalT1_3',
            'StaffTotalNationalT1_3',
            'VolunteersPerStudentT1_3',
            'VolunteerMaterialsTotalT1_3',
            'ParentsPerStudentT1_3',
            'ParentsTotalT1_3',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportT1_3',
            HoursAdmins: 'HoursAdminsSupportT1_3',
            NumSMHP: 'NumSMHPSupportT1_3',
            HoursSMHP: 'HoursSMHPSupportT1_3',
            NumTeachers: 'NumTeachersSupportT1_3',
            HoursTeachers: 'HoursTeachersSupportT1_3',
            NumOthers: 'NumOthersSupportT1_3',
            HoursOthers: 'HoursOthersSupportT1_3',
        },
        feeTag: 'FeeT1_3',
        stateOverheadTag: 'OverheadStateT1_3',
        nationalOverheadTag: 'OverheadNationalT1_3',
        titleHandle: 'OT1TableTitle',
        subTitleTag: 'OtherTier1Name3',
        studentCountTag: 'NumStudentsT1_3',
        hoursCountTag: 'CoachingHoursT1_3',
        condition: devTags => {
            return (
                +devTags.AddOtherTier1Selected === 1 &&
                devTags.OtherTier1Name3 &&
                devTags.OtherTier1Name3 !== ''
            );
        },
    },
    {
        handle: 'T2_1',
        label: 'Tier 2 Program',
        stateValues: [
            'TrainingPerStudentStateT2_1',
            'TrainingTotalStateT2_1',
            'StaffPerStudentStateT2_1',
            'StaffTotalStateT2_1',
            'VolunteersPerStudentT2_1',
            'VolunteerMaterialsTotalT2_1',
            'ParentsPerStudentT2_1',
            'ParentsTotalT2_1',
        ],
        nationalValues: [
            'TrainingPerStudentNationalT2_1',
            'TrainingTotalNationalT2_1',
            'StaffPerStudentNationalT2_1',
            'StaffTotalNationalT2_1',
            'VolunteersPerStudentT2_1',
            'VolunteerMaterialsTotalT2_1',
            'ParentsPerStudentT2_1',
            'ParentsTotalT2_1',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportT2_1',
            HoursAdmins: 'HoursAdminsSupportT2_1',
            NumSMHP: 'NumSMHPSupportT2_1',
            HoursSMHP: 'HoursSMHPSupportT2_1',
            NumTeachers: 'NumTeachersSupportT2_1',
            HoursTeachers: 'HoursTeachersSupportT2_1',
            NumOthers: 'NumOthersSupportT2_1',
            HoursOthers: 'HoursOthersSupportT2_1',
        },
        feeTag: 'FeeT2_1',
        stateOverheadTag: 'OverheadStateT2_1',
        nationalOverheadTag: 'OverheadNationalT2_1',
        titleHandle: 'T2TableTitle',
        subTitleTag: 'Tier2Name1',
        studentCountTag: 'NumStudentsT2_1',
        hoursCountTag: 'CoachingHoursT2_1',
        condition: devTags => {
            return +devTags.Tier2Selected === 1;
        },
    },
    {
        handle: 'T2_2',
        label: 'Tier 2 Program',
        stateValues: [
            'TrainingPerStudentStateT2_2',
            'TrainingTotalStateT2_2',
            'StaffPerStudentStateT2_2',
            'StaffTotalStateT2_2',
            'VolunteersPerStudentT2_2',
            'VolunteerMaterialsTotalT2_2',
            'ParentsPerStudentT2_2',
            'ParentsTotalT2_2',
        ],
        nationalValues: [
            'TrainingPerStudentNationalT2_2',
            'TrainingTotalNationalT2_2',
            'StaffPerStudentNationalT2_2',
            'StaffTotalNationalT2_2',
            'VolunteersPerStudentT2_2',
            'VolunteerMaterialsTotalT2_2',
            'ParentsPerStudentT2_2',
            'ParentsTotalT2_2',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportT2_2',
            HoursAdmins: 'HoursAdminsSupportT2_2',
            NumSMHP: 'NumSMHPSupportT2_2',
            HoursSMHP: 'HoursSMHPSupportT2_2',
            NumTeachers: 'NumTeachersSupportT2_2',
            HoursTeachers: 'HoursTeachersSupportT2_2',
            NumOthers: 'NumOthersSupportT2_2',
            HoursOthers: 'HoursOthersSupportT2_2',
        },
        feeTag: 'FeeT2_2',
        stateOverheadTag: 'OverheadStateT2_2',
        nationalOverheadTag: 'OverheadNationalT2_2',
        titleHandle: 'T2TableTitle',
        subTitleTag: 'Tier2Name2',
        studentCountTag: 'NumStudentsT2_2',
        hoursCountTag: 'CoachingHoursT2_2',
        condition: devTags => {
            return (
                +devTags.AddTier2Selected === 1 && devTags.Tier2Name2 && devTags.Tier2Name2 !== ''
            );
        },
    },
    {
        handle: 'T2_3',
        label: 'Tier 2 Program',
        stateValues: [
            'TrainingPerStudentStateT2_3',
            'TrainingTotalStateT2_3',
            'StaffPerStudentStateT2_3',
            'StaffTotalStateT2_3',
            'VolunteersPerStudentT2_3',
            'VolunteerMaterialsTotalT2_3',
            'ParentsPerStudentT2_3',
            'ParentsTotalT2_3',
        ],
        nationalValues: [
            'TrainingPerStudentNationalT2_3',
            'TrainingTotalNationalT2_3',
            'StaffPerStudentNationalT2_3',
            'StaffTotalNationalT2_3',
            'VolunteersPerStudentT2_3',
            'VolunteerMaterialsTotalT2_3',
            'ParentsPerStudentT2_3',
            'ParentsTotalT2_3',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportT2_3',
            HoursAdmins: 'HoursAdminsSupportT2_3',
            NumSMHP: 'NumSMHPSupportT2_3',
            HoursSMHP: 'HoursSMHPSupportT2_3',
            NumTeachers: 'NumTeachersSupportT2_3',
            HoursTeachers: 'HoursTeachersSupportT2_3',
            NumOthers: 'NumOthersSupportT2_3',
            HoursOthers: 'HoursOthersSupportT2_3',
        },
        feeTag: 'FeeT2_3',
        stateOverheadTag: 'OverheadStateT2_3',
        nationalOverheadTag: 'OverheadNationalT2_3',
        titleHandle: 'T2TableTitle',
        subTitleTag: 'Tier2Name3',
        studentCountTag: 'NumStudentsT2_3',
        hoursCountTag: 'CoachingHoursT2_3',
        condition: devTags => {
            return (
                +devTags.AddTier2Selected === 1 && devTags.Tier2Name3 && devTags.Tier2Name3 !== ''
            );
        },
    },
    {
        handle: 'T3_1',
        label: 'Tier 3 Program',
        stateValues: [
            'TrainingPerStudentStateT3_1',
            'TrainingTotalStateT3_1',
            'StaffPerStudentStateT3_1',
            'StaffTotalStateT3_1',
            'VolunteersPerStudentT3_1',
            'VolunteerMaterialsTotalT3_1',
            'ParentsPerStudentT3_1',
            'ParentsTotalT3_1',
        ],
        nationalValues: [
            'TrainingPerStudentNationalT3_1',
            'TrainingTotalNationalT3_1',
            'StaffPerStudentNationalT3_1',
            'StaffTotalNationalT3_1',
            'VolunteersPerStudentT3_1',
            'VolunteerMaterialsTotalT3_1',
            'ParentsPerStudentT3_1',
            'ParentsTotalT3_1',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportT3_1',
            HoursAdmins: 'HoursAdminsSupportT3_1',
            NumSMHP: 'NumSMHPSupportT3_1',
            HoursSMHP: 'HoursSMHPSupportT3_1',
            NumTeachers: 'NumTeachersSupportT3_1',
            HoursTeachers: 'HoursTeachersSupportT3_1',
            NumOthers: 'NumOthersSupportT3_1',
            HoursOthers: 'HoursOthersSupportT3_1',
        },
        feeTag: 'FeeT3_1',
        stateOverheadTag: 'OverheadStateT3_1',
        nationalOverheadTag: 'OverheadNationalT3_1',
        titleHandle: 'T3TableTitle',
        subTitleTag: 'Tier3Name1',
        studentCountTag: 'NumStudentsT3_1',
        hoursCountTag: 'CoachingHoursT3_1',
        condition: devTags => {
            return +devTags.Tier3Selected === 1;
        },
    },
    {
        handle: 'T3_2',
        label: 'Tier 3 Program',
        stateValues: [
            'TrainingPerStudentStateT3_2',
            'TrainingTotalStateT3_2',
            'StaffPerStudentStateT3_2',
            'StaffTotalStateT3_2',
            'VolunteersPerStudentT3_2',
            'VolunteerMaterialsTotalT3_2',
            'ParentsPerStudentT3_2',
            'ParentsTotalT3_2',
        ],
        nationalValues: [
            'TrainingPerStudentNationalT3_2',
            'TrainingTotalNationalT3_2',
            'StaffPerStudentNationalT3_2',
            'StaffTotalNationalT3_2',
            'VolunteersPerStudentT3_2',
            'VolunteerMaterialsTotalT3_2',
            'ParentsPerStudentT3_2',
            'ParentsTotalT3_2',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportT3_2',
            HoursAdmins: 'HoursAdminsSupportT3_2',
            NumSMHP: 'NumSMHPSupportT3_2',
            HoursSMHP: 'HoursSMHPSupportT3_2',
            NumTeachers: 'NumTeachersSupportT3_2',
            HoursTeachers: 'HoursTeachersSupportT3_2',
            NumOthers: 'NumOthersSupportT3_2',
            HoursOthers: 'HoursOthersSupportT3_2',
        },
        feeTag: 'FeeT3_2',
        stateOverheadTag: 'OverheadStateT3_2',
        nationalOverheadTag: 'OverheadNationalT3_2',
        titleHandle: 'T3TableTitle',
        subTitleTag: 'Tier3Name2',
        studentCountTag: 'NumStudentsT3_2',
        hoursCountTag: 'CoachingHoursT3_2',
        condition: devTags => {
            return (
                +devTags.AddTier3Selected === 1 && devTags.Tier3Name2 && devTags.Tier3Name2 !== ''
            );
        },
    },
    {
        handle: 'T3_3',
        label: 'Tier 3 Program',
        stateValues: [
            'TrainingPerStudentStateT3_3',
            'TrainingTotalStateT3_3',
            'StaffPerStudentStateT3_3',
            'StaffTotalStateT3_3',
            'VolunteersPerStudentT3_3',
            'VolunteerMaterialsTotalT3_3',
            'ParentsPerStudentT3_3',
            'ParentsTotalT3_3',
        ],
        nationalValues: [
            'TrainingPerStudentNationalT3_3',
            'TrainingTotalNationalT3_3',
            'StaffPerStudentNationalT3_3',
            'StaffTotalNationalT3_3',
            'VolunteersPerStudentT3_3',
            'VolunteerMaterialsTotalT3_3',
            'ParentsPerStudentT3_3',
            'ParentsTotalT3_3',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportT3_3',
            HoursAdmins: 'HoursAdminsSupportT3_3',
            NumSMHP: 'NumSMHPSupportT3_3',
            HoursSMHP: 'HoursSMHPSupportT3_3',
            NumTeachers: 'NumTeachersSupportT3_3',
            HoursTeachers: 'HoursTeachersSupportT3_3',
            NumOthers: 'NumOthersSupportT3_3',
            HoursOthers: 'HoursOthersSupportT3_3',
        },
        feeTag: 'FeeT3_3',
        stateOverheadTag: 'OverheadStateT3_3',
        nationalOverheadTag: 'OverheadNationalT3_3',
        titleHandle: 'T3TableTitle',
        subTitleTag: 'Tier3Name3',
        studentCountTag: 'NumStudentsT3_3',
        hoursCountTag: 'CoachingHoursT3_3',
        condition: devTags => {
            return (
                +devTags.AddTier3Selected === 1 && devTags.Tier3Name3 && devTags.Tier3Name3 !== ''
            );
        },
    },
    {
        handle: 'SD1',
        label: 'Mentoring Program',
        stateValues: [
            'TrainingPerStudentStateSD1',
            'TrainingTotalStateSD1',
            'StaffPerStudentStateSD1',
            'StaffTotalStateSD1',
            'VolunteersPerStudentSD1',
            'VolunteerMaterialsTotalSD1',
            'ParentsPerStudentSD1',
            'ParentsTotalSD1',
        ],
        nationalValues: [
            'TrainingPerStudentNationalSD1',
            'TrainingTotalNationalSD1',
            'StaffPerStudentNationalSD1',
            'StaffTotalNationalSD1',
            'VolunteersPerStudentSD1',
            'VolunteerMaterialsTotalSD1',
            'ParentsPerStudentSD1',
            'ParentsTotalSD1',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportSD1',
            HoursAdmins: 'HoursAdminsSupportSD1',
            NumSMHP: 'NumSMHPSupportSD1',
            HoursSMHP: 'HoursSMHPSupportSD1',
            NumTeachers: 'NumTeachersSupportSD1',
            HoursTeachers: 'HoursTeachersSupportSD1',
            NumOthers: 'NumOthersSupportSD1',
            HoursOthers: 'HoursOthersSupportSD1',
        },
        feeTag: 'FeeSD1',
        stateOverheadTag: 'OverheadStateSD1',
        nationalOverheadTag: 'OverheadNationalSD1',
        titleHandle: 'SDPTableTitle',
        subTitleTag: 'SDPName1',
        studentCountTag: 'NumStudentsSD1',
        hoursCountTag: 'CoachingHoursSD1',
        condition: devTags => {
            return +devTags.SDPSelected === 1;
        },
    },
    {
        handle: 'SD2',
        label: 'Mentoring Program',
        stateValues: [
            'TrainingPerStudentStateSD2',
            'TrainingTotalStateSD2',
            'StaffPerStudentStateSD2',
            'StaffTotalStateSD2',
            'VolunteersPerStudentSD2',
            'VolunteerMaterialsTotalSD2',
            'ParentsPerStudentSD2',
            'ParentsTotalSD2',
        ],
        nationalValues: [
            'TrainingPerStudentNationalSD2',
            'TrainingTotalNationalSD2',
            'StaffPerStudentNationalSD2',
            'StaffTotalNationalSD2',
            'VolunteersPerStudentSD2',
            'VolunteerMaterialsTotalSD2',
            'ParentsPerStudentSD2',
            'ParentsTotalSD2',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportSD2',
            HoursAdmins: 'HoursAdminsSupportSD2',
            NumSMHP: 'NumSMHPSupportSD2',
            HoursSMHP: 'HoursSMHPSupportSD2',
            NumTeachers: 'NumTeachersSupportSD2',
            HoursTeachers: 'HoursTeachersSupportSD2',
            NumOthers: 'NumOthersSupportSD2',
            HoursOthers: 'HoursOthersSupportSD2',
        },
        feeTag: 'FeeSD2',
        stateOverheadTag: 'OverheadStateSD2',
        nationalOverheadTag: 'OverheadNationalSD2',
        titleHandle: 'SDPTableTitle',
        subTitleTag: 'SDPName2',
        studentCountTag: 'NumStudentsSD2',
        hoursCountTag: 'CoachingHoursSD2',
        condition: devTags => {
            return +devTags.AddSDPSelected === 1 && devTags.SDPName2 && devTags.SDPName2 !== '';
        },
    },
    {
        handle: 'SD3',
        label: 'Mentoring Program',
        stateValues: [
            'TrainingPerStudentStateSD3',
            'TrainingTotalStateSD3',
            'StaffPerStudentStateSD3',
            'StaffTotalStateSD3',
            'VolunteersPerStudentSD3',
            'VolunteerMaterialsTotalSD3',
            'ParentsPerStudentSD3',
            'ParentsTotalSD3',
        ],
        nationalValues: [
            'TrainingPerStudentNationalSD3',
            'TrainingTotalNationalSD3',
            'StaffPerStudentNationalSD3',
            'StaffTotalNationalSD3',
            'VolunteersPerStudentSD3',
            'VolunteerMaterialsTotalSD3',
            'ParentsPerStudentSD3',
            'ParentsTotalSD3',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportSD3',
            HoursAdmins: 'HoursAdminsSupportSD3',
            NumSMHP: 'NumSMHPSupportSD3',
            HoursSMHP: 'HoursSMHPSupportSD3',
            NumTeachers: 'NumTeachersSupportSD3',
            HoursTeachers: 'HoursTeachersSupportSD3',
            NumOthers: 'NumOthersSupportSD3',
            HoursOthers: 'HoursOthersSupportSD3',
        },
        feeTag: 'FeeSD3',
        stateOverheadTag: 'OverheadStateSD3',
        nationalOverheadTag: 'OverheadNationalSD3',
        titleHandle: 'SDPTableTitle',
        subTitleTag: 'SDPName3',
        studentCountTag: 'NumStudentsSD3',
        hoursCountTag: 'CoachingHoursSD3',
        condition: devTags => {
            return +devTags.AddSDPSelected === 1 && devTags.SDPName3 && devTags.SDPName3 !== '';
        },
    },
    {
        handle: 'OP1',
        label: 'Other Type',
        stateValues: [
            'TrainingPerStudentStateOP1',
            'TrainingTotalStateOP1',
            'StaffPerStudentStateOP1',
            'StaffTotalStateOP1',
            'VolunteersPerStudentOP1',
            'VolunteerMaterialsTotalOP1',
            'ParentsPerStudentOP1',
            'ParentsTotalOP1',
        ],
        nationalValues: [
            'TrainingPerStudentNationalOP1',
            'TrainingTotalNationalOP1',
            'StaffPerStudentNationalOP1',
            'StaffTotalNationalOP1',
            'VolunteersPerStudentOP1',
            'VolunteerMaterialsTotalOP1',
            'ParentsPerStudentOP1',
            'ParentsTotalOP1',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportOP1',
            HoursAdmins: 'HoursAdminsSupportOP1',
            NumSMHP: 'NumSMHPSupportOP1',
            HoursSMHP: 'HoursSMHPSupportOP1',
            NumTeachers: 'NumTeachersSupportOP1',
            HoursTeachers: 'HoursTeachersSupportOP1',
            NumOthers: 'NumOthersSupportOP1',
            HoursOthers: 'HoursOthersSupportOP1',
        },
        feeTag: 'FeeOP1',
        stateOverheadTag: 'OverheadStateOP1',
        nationalOverheadTag: 'OverheadNationalOP1',
        titleHandle: 'otherTableTitle',
        subTitleTag: 'OtherTypeName1',
        studentCountTag: 'NumStudentsOP1',
        hoursCountTag: 'CoachingHoursOP1',
        condition: devTags => {
            return +devTags.OtherTypeSelected === 1;
        },
    },
    {
        handle: 'OP2',
        label: 'Other Type',
        stateValues: [
            'TrainingPerStudentStateOP2',
            'TrainingTotalStateOP2',
            'StaffPerStudentStateOP2',
            'StaffTotalStateOP2',
            'VolunteersPerStudentOP2',
            'VolunteerMaterialsTotalOP2',
            'ParentsPerStudentOP2',
            'ParentsTotalOP2',
        ],
        nationalValues: [
            'TrainingPerStudentNationalOP2',
            'TrainingTotalNationalOP2',
            'StaffPerStudentNationalOP2',
            'StaffTotalNationalOP2',
            'VolunteersPerStudentOP2',
            'VolunteerMaterialsTotalOP2',
            'ParentsPerStudentOP2',
            'ParentsTotalOP2',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportOP2',
            HoursAdmins: 'HoursAdminsSupportOP2',
            NumSMHP: 'NumSMHPSupportOP2',
            HoursSMHP: 'HoursSMHPSupportOP2',
            NumTeachers: 'NumTeachersSupportOP2',
            HoursTeachers: 'HoursTeachersSupportOP2',
            NumOthers: 'NumOthersSupportOP2',
            HoursOthers: 'HoursOthersSupportOP2',
        },
        feeTag: 'FeeOP2',
        stateOverheadTag: 'OverheadStateOP2',
        nationalOverheadTag: 'OverheadNationalOP2',
        titleHandle: 'otherTableTitle',
        subTitleTag: 'OtherTypeName2',
        studentCountTag: 'NumStudentsOP2',
        hoursCountTag: 'CoachingHoursOP2',
        condition: devTags => {
            return (
                +devTags.AddOtherTypeSelected === 1 &&
                devTags.OtherTypeName2 &&
                devTags.OtherTypeName2 !== ''
            );
        },
    },
    {
        handle: 'OP3',
        label: 'Other Type',
        stateValues: [
            'TrainingPerStudentStateOP3',
            'TrainingTotalStateOP3',
            'StaffPerStudentStateOP3',
            'StaffTotalStateOP3',
            'VolunteersPerStudentOP3',
            'VolunteerMaterialsTotalOP3',
            'ParentsPerStudentOP3',
            'ParentsTotalOP3',
        ],
        nationalValues: [
            'TrainingPerStudentNationalOP3',
            'TrainingTotalNationalOP3',
            'StaffPerStudentNationalOP3',
            'StaffTotalNationalOP3',
            'VolunteersPerStudentOP3',
            'VolunteerMaterialsTotalOP3',
            'ParentsPerStudentOP3',
            'ParentsTotalOP3',
        ],
        initialFormValues: {
            NumAdmins: 'NumAdminsSupportOP3',
            HoursAdmins: 'HoursAdminsSupportOP3',
            NumSMHP: 'NumSMHPSupportOP3',
            HoursSMHP: 'HoursSMHPSupportOP3',
            NumTeachers: 'NumTeachersSupportOP3',
            HoursTeachers: 'HoursTeachersSupportOP3',
            NumOthers: 'NumOthersSupportOP3',
            HoursOthers: 'HoursOthersSupportOP3',
        },
        feeTag: 'FeeOP3',
        stateOverheadTag: 'OverheadStateOP3',
        nationalOverheadTag: 'OverheadNationalOP3',
        titleHandle: 'otherTableTitle',
        subTitleTag: 'OtherTypeName3',
        studentCountTag: 'NumStudentsOP3',
        hoursCountTag: 'CoachingHoursOP3',
        condition: devTags => {
            return (
                +devTags.AddOtherTypeSelected === 1 &&
                devTags.OtherTypeName3 &&
                devTags.OtherTypeName3 !== ''
            );
        },
    },
];

const Boilerplate = window.cccisd.boilerplate;

class CostCalculatorReport extends React.Component {
    static propTypes = {
        settings: PropTypes.object,
        isPreview: PropTypes.bool,
        editCurrentSurvey: PropTypes.func,
        startNewSurvey: PropTypes.func,
        deploymentId: PropTypes.number,
        user: PropTypes.object,
        resumeOn: PropTypes.bool,
        loggedIn: PropTypes.bool,
        viewResponse: PropTypes.func,
        surveyList: PropTypes.array,
        progressList: PropTypes.array,
    };

    state = {
        displayWage: 'state',
        data: null,
        loading: true,
        openAll: false,
        formInput: {},
        studentCounts: {},
    };

    componentDidMount = async () => {
        let data = {
            graphql: fakeGraphQLData.assignmentProgress,
            scoring: fakeScoringData,
        };

        if (!this.props.isPreview) {
            // initial Data get
            await this.getData(true);
        } else {
            this.setState({ data, loading: false });
        }
    };

    componentDidUpdate(prevProps) {
        // Only when changing reports / surveys
        if (prevProps.deploymentId !== this.props.deploymentId) {
            this.getData();
        }
    }

    getData = async first => {
        const {
            user: { pawnId, pawnHash },
            deploymentId,
        } = this.props;

        this.setState({ loading: true });

        const response = await client.query({
            query: metricsQuery,
            variables: {
                pawnId,
                deploymentId,
            },
            fetchPolicy: 'network-only',
        });

        const values = {
            assignment: 'assignment1',
            pawnId,
            pawnHash,
            deploymentId,
            responseSet: deploymentId,
        };

        const result = await axios.post(Boilerplate.route('app.cost.calculator.scoring'), values);

        if (first) {
            this.setState({
                data: {
                    graphql: response.data.roles.anyRole.assignmentProgress,
                    scoring: result.data.values,
                },
                loading: false,
                formInput: {},
                studentCounts: {},
                isRecalc: false,
                recalcData: {},
            });
        } else {
            this.setState({
                data: {
                    graphql: response.data.roles.anyRole.assignmentProgress,
                    scoring: result.data.values,
                },
                loading: false,
                formInput: {},
                studentCounts: {},
                isRecalc: false,
                recalcData: {},
            });
        }
    };

    getInitialValues = handle => {
        const table = Tables.find(item => item.handle === handle);
        const tags = table.initialFormValues;
        const metrics = this.state.data.graphql.initialValues;
        let initialValues = {};

        if (tags) {
            for (const [key, value] of Object.entries(tags)) {
                initialValues[key] = metrics[value];
            }
        }

        return initialValues;
    };

    getNumberWithCommas = number => {
        const roundedNumber = Math.round(number * 100) / 100;

        return roundedNumber
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    getDescription = () => {
        const { description } = this.props.settings;
        const totals = this.getTotals(this.state.displayWage);

        if (description.includes('%TOTAL%')) {
            const newDescription = description.replace('%TOTAL%', totals.total);
            if (newDescription.includes('%STUDENT%')) {
                const finalDescription = newDescription.replace('%STUDENT%', totals.perStudent);

                return finalDescription;
            }

            return newDescription;
        }

        return description;
    };

    getTotals = displayWage => {
        const {
            data: {
                scoring: { totals },
                graphql: { reportMetrics },
            },
        } = this.state;

        const totalTable = Tables[0];
        const countTag = totalTable.studentCountTag;
        const totalStudents = reportMetrics[countTag];
        const tags = displayWage === 'state' ? totalTable.stateValues : totalTable.nationalValues;
        const fee = totals[totalTable.feeTag] || 0;
        const overHead =
            displayWage === 'state'
                ? totals[totalTable.stateOverheadTag]
                : totals[totalTable.nationalOverheadTag];
        const checkOverhead = overHead ? overHead : 0;
        const feePerStudent = totalStudents ? fee / totalStudents : 0;
        const overHeadPerStudent = totalStudents ? checkOverhead / totalStudents : 0;
        const perStudentTags = tags.filter(item => item.includes('PerStudent'));
        const yearTags = tags.filter(item => !item.includes('PerStudent'));

        let perStudentTotal = feePerStudent + overHeadPerStudent;

        perStudentTags.forEach(tag => {
            const value = +totals[tag];

            if (value) {
                perStudentTotal += value;
            }
        });

        let yearTotal = fee + checkOverhead;

        yearTags.forEach(tag => {
            const value = +totals[tag];
            if (value) {
                yearTotal += value;
            }
        });

        const total = this.getNumberWithCommas(yearTotal);
        const perStudent = this.getNumberWithCommas(perStudentTotal);
        return { total, perStudent };
    };

    download = () => {
        this.setState({ openAll: true });
        setTimeout(() => {
            this.report.download();
            Notification({
                message: 'Please wait a moment while your PDF is prepared.',
                type: 'info',
            });
        }, 1000);
    };

    resetOpenAll = () => {
        this.setState({
            openAll: false,
        });
    };

    recalculate = async () => {
        const { studentCounts, formInput } = this.state;
        const { user, deploymentId } = this.props;
        const requiredTables = Tables.filter(item =>
            item.condition(this.state.data.graphql.reportMetrics)
        ).filter(item => item.handle !== 'totals');
        const input = {};

        requiredTables.forEach(table => {
            const initialValues = this.getInitialValues(table.handle);
            const countObj = studentCounts[table.handle];
            const formObj = formInput[table.handle];
            if (countObj && formObj) {
                input[table.handle] = {
                    ...formObj,
                    ...countObj,
                };
            } else if (countObj && !formObj) {
                input[table.handle] = {
                    ...initialValues,
                    ...countObj,
                };
            } else if (formObj && !countObj) {
                input[table.handle] = {
                    ...formObj,
                    students: this.state.data.graphql.reportMetrics[table.studentCountTag],
                };
            } else if (!formObj && !countObj) {
                input[table.handle] = {
                    ...initialValues,
                    students: this.state.data.graphql.reportMetrics[table.studentCountTag],
                };
            }
        });
        if (!this.props.isPreview) {
            if (!_isEmpty(input)) {
                this.setState({
                    loading: true,
                });
                const result = await axios.post(Boilerplate.route('app.cost.calculator.scoring'), {
                    assignment: 'assignment1',
                    pawnId: user.pawnId,
                    pawnHash: user.pawnHash,
                    deploymentId,
                    userInput: input,
                    responseSet: deploymentId,
                });
                if (result && result.data && result.data.success === true) {
                    this.setState({
                        recalcData: input,
                        loading: false,
                        data: { scoring: result.data.values, graphql: this.state.data.graphql },
                        formInput: {},
                        studentCounts: {},
                        isRecalc: true,
                    });
                }
            } else {
                Notification({
                    message: 'Please input new numbers to recalculate.',
                    type: 'warning',
                });
            }
        } else {
            let fakeData = {
                graphql: fakeGraphQLData.assignmentProgress,
                scoring: fakeScoringData,
            };
            this.setState({
                recalcData: input,
                data: fakeData,
                formInput: {},
                studentCounts: {},
                isRecalc: true,
            });
        }
    };

    resetCounts = () => {
        this.getData();
    };

    onSelectionChange = event => {
        const value = event.target.value;
        this.setState({
            displayWage: value === '0' ? 'state' : 'national',
        });
    };

    onHoursFormSubmit = (values, key) => {
        const noFormInput = _isEmpty(this.state.formInput);
        if (noFormInput) {
            this.setState({
                formInput: {
                    [key]: values,
                },
            });
        } else {
            this.setState({
                formInput: {
                    ...this.state.formInput,
                    [key]: values,
                },
            });
        }
    };

    saveCounts = async (key, count) => {
        let regex = /^(?:[1-9]\d*)$/;
        if (!regex.test(count) || count === '') {
            Notification({
                message: 'Total # of Students Participating must be number greater than "0"',
                type: 'danger',
                duration: 2300,
            });
        }
        const noUserInput = _isEmpty(this.state.studentCounts);

        if (noUserInput) {
            await this.setState({
                studentCounts: {
                    [key]: {
                        students: count,
                    },
                },
            });
        } else {
            await this.setState({
                studentCounts: {
                    ...this.state.studentCounts,
                    [key]: {
                        students: count,
                    },
                },
            });
        }
    };

    renderSelect = () => {
        const options = [
            {
                value: 0,
                label: 'State',
            },
            {
                value: 1,
                label: 'National',
            },
        ];

        return (
            <Select
                options={options}
                value={this.state.displayWage === 'state' ? 0 : 1}
                onSelectionChange={this.onSelectionChange}
            />
        );
    };

    renderTables = num => {
        const {
            isRecalc,
            data: { graphql, scoring },
            formInput,
            studentCounts,
            displayWage,
            openAll,
            recalcData,
        } = this.state;
        const { settings, deploymentId } = this.props;
        const devTags = graphql.reportMetrics;
        const renderedTable = Tables[num];
        const filteredTables = Tables.filter(table => table.condition(devTags)).slice(1);
        const studentCountTags = filteredTables.map(table => table.studentCountTag);

        if (renderedTable && renderedTable.condition(devTags)) {
            const tableData = scoring[renderedTable.handle];
            const currentFormInput = formInput[renderedTable.handle];
            const currentStudents = studentCounts[renderedTable.handle];
            const recalcValues =
                recalcData && !_isEmpty(recalcData) && recalcData[renderedTable.handle];

            return (
                <RenderTables
                    key={num}
                    tableConfig={renderedTable}
                    studentCountTags={studentCountTags}
                    data={{ scoring: tableData, graphql }}
                    settings={settings}
                    index={num}
                    openAll={openAll}
                    resetOpenAll={this.resetOpenAll}
                    displayWage={displayWage}
                    saveCounts={this.saveCounts}
                    deploymentId={deploymentId}
                    onHoursFormSubmit={this.onHoursFormSubmit}
                    currentFormInput={currentFormInput}
                    currentStudents={currentStudents}
                    isRecalc={isRecalc}
                    recalcData={recalcValues}
                />
            );
        }

        return null;
    };

    renderStartNew = () => {
        if (!this.props.resumeOn && this.props.loggedIn) {
            return (
                <button
                    type="button"
                    className={`btn btn-default ${style.startButton}`}
                    onClick={this.props.startNewSurvey}
                    disabled={this.props.isPreview}
                >
                    <Plus /> Start New
                </button>
            );
        }
        if (!this.props.loggedIn) {
            return (
                <button
                    type="button"
                    className={`btn btn-default ${style.startButton}`}
                    onClick={this.props.startNewSurvey}
                    disabled={this.props.isPreview}
                >
                    <Plus /> Start New
                </button>
            );
        }
        return null;
    };

    renderButtons = () => {
        const {
            isRecalc,
            data: { graphql },
            formInput,
            studentCounts,
            recalcData,
        } = this.state;

        const requiredTables = Tables.filter(item => item.condition(graphql.reportMetrics)).filter(
            item => item.handle !== 'totals'
        );

        let changesArray = [];
        let NaNArray = [];
        const userFormChanges = !_isEmpty(formInput);
        const userNumberChanges = !_isEmpty(studentCounts);
        const userChanges = userFormChanges || userNumberChanges;
        let regex = /^(?:[1-9]\d*)$/;
        requiredTables.forEach(item => {
            const initialFormValues = this.getInitialValues(item.handle);
            const compareValues =
                recalcData && !_isEmpty(recalcData) ? recalcData[item.handle] : initialFormValues;
            const currentFormInput = formInput[item.handle];

            const initialStudents = { students: graphql.reportMetrics[item.studentCountTag] };

            const currentStudents = {
                students:
                    !_isEmpty(studentCounts) && studentCounts[item.handle]
                        ? studentCounts[item.handle].students
                        : null,
            };
            const compareStudents =
                recalcData && !_isEmpty(recalcData) && recalcData[item.handle]
                    ? { students: recalcData[item.handle].students }
                    : initialStudents;

            if (
                currentStudents &&
                currentStudents.students &&
                (!regex.test(currentStudents.students) || currentStudents.students === '')
            ) {
                NaNArray.push('NaN');
            }

            if (!userFormChanges && !userNumberChanges) {
                changesArray.push(false);
            } else if (userFormChanges && !userNumberChanges) {
                if (_isEqual(compareValues, currentFormInput) || currentFormInput === undefined) {
                    changesArray.push(false);
                } else {
                    changesArray.push(true);
                }
            } else if (userNumberChanges && !userFormChanges) {
                if (_isEqual(compareStudents, currentStudents) || !currentStudents.students) {
                    changesArray.push(false);
                } else {
                    changesArray.push(true);
                }
            } else if (userNumberChanges && userFormChanges) {
                const numberChanges =
                    !_isEqual(compareStudents, currentStudents) && currentStudents.students;
                const formChanges =
                    !_isEqual(compareValues, currentFormInput) && currentFormInput !== undefined;

                if (numberChanges || formChanges) {
                    changesArray.push(true);
                } else {
                    changesArray.push(false);
                }
            }
        });
        // conditions
        const userChangesNull = changesArray.every(item => item === false);
        const hasNaN = NaNArray.length > 0;

        let resetText = '';
        if ((!userChanges && !isRecalc) || (userChangesNull && !isRecalc)) {
            resetText = 'Please enter new data to reset.';
        } else {
            resetText = 'Reset Calculation';
        }

        return (
            <BrowserOnly>
                <div className={style.recalcRow}>
                    <div className={style.recalcText}>
                        The dropdowns below contain your implementation selection(s). You may adjust
                        the <strong>Total # Hours Coaching</strong> and the{' '}
                        <strong>Total # Students Participating</strong> by clicking within each.
                    </div>
                    <div className={style.recalcButtons}>
                        <Tooltip title={resetText} placement="top">
                            <button
                                type="button"
                                onClick={this.resetCounts}
                                disabled={
                                    (!userChanges && !isRecalc) || (userChangesNull && !isRecalc)
                                }
                                className={`btn btn-default ${style.resetButton}`}
                            >
                                <Rotate /> Reset
                            </button>
                        </Tooltip>
                        <Tooltip
                            title={
                                !userChanges || userChangesNull || hasNaN
                                    ? 'Please enter new data to recalculate.'
                                    : 'Recalculate'
                            }
                            placement="top"
                        >
                            <button
                                type="button"
                                disabled={!userChanges || userChangesNull || hasNaN}
                                onClick={this.recalculate}
                                className={`btn btn-default ${
                                    !userChanges || userChangesNull || hasNaN
                                        ? style.recalcDisabled
                                        : style.recalcButton
                                }`}
                            >
                                <Calculator /> Recalculate
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </BrowserOnly>
        );
    };

    getFirstInProgress = () => {
        const { progressList } = this.props;

        if (progressList) {
            const startedList = progressList.filter(item => item.flowHandle === 'survey6');
            const completedList = progressList.filter(item => item.flowHandle === 'survey13');
            let firstSurvey;

            startedList.forEach(item => {
                const match = completedList.find(thing => thing.responseSet === item.responseSet);
                if (!match) {
                    firstSurvey = item;
                }
            });
            return firstSurvey;
        }
        return null;
    };

    renderFirstPage = () => {
        const { data } = this.state;
        const { title } = this.props.settings;
        const { deploymentId, surveyList } = this.props;

        const completedDate = data && data.graphql.completedDate;
        const schoolName = data && data.graphql.reportMetrics.SchoolName;
        const firstInProgress = this.getFirstInProgress();
        const isLatest = surveyList ? deploymentId === surveyList[1].responseSet : false;
        const inProgress =
            surveyList && firstInProgress
                ? firstInProgress.responseSet !== surveyList[1].responseSet
                : false;
        const devTags = data && data.graphql.reportMetrics;
        const filteredTables = devTags && Tables.filter(table => table.condition(devTags));
        const secondTable = Tables.find(item => item.handle === filteredTables[1].handle);
        const secondTableIndex = Tables.indexOf(secondTable);
        const newDescription = this.getDescription();

        return (
            <Page>
                <div className={style.wageInfoRow}>
                    Wage Info:{' '}
                    <BrowserOnly>
                        <Tooltip
                            title="Select to view cost based on wages at the state or national level."
                            placement="top"
                        >
                            <Info />
                        </Tooltip>{' '}
                        {this.renderSelect()}
                    </BrowserOnly>
                    <PrintOnly>
                        {this.state.displayWage === 'state' ? 'State' : 'National'}
                    </PrintOnly>
                </div>
                <PrintOnly>
                    <p />
                </PrintOnly>
                <div className={style.titleRow}>
                    <h3>{title}</h3>
                    <div className={style.buttonBox}>
                        <BrowserOnly>
                            {this.renderStartNew()}
                            <button
                                type="button"
                                className={`btn btn-default ${style.button}`}
                                onClick={this.download}
                            >
                                <Download /> Download Report
                            </button>
                        </BrowserOnly>
                    </div>
                </div>

                {this.state.data && (
                    <>
                        <PrintOnly>
                            <p />
                        </PrintOnly>
                        <div className={style.infoRow}>
                            <div>
                                <strong>School: </strong>
                                {schoolName} | <strong>Date: </strong>
                                <span className={style.date}>{completedDate}</span>
                            </div>
                            <BrowserOnly>
                                <div className={style.responsesBox}>
                                    <span style={{ marginRight: '1em' }}>Responses:</span>
                                    <a
                                        onClick={this.props.viewResponse}
                                        style={{
                                            marginRight: `${isLatest ? '1em' : '0'}`,
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <Download /> Download
                                    </a>
                                    {isLatest && !inProgress && (
                                        <>
                                            |
                                            <a
                                                onClick={this.props.editCurrentSurvey}
                                                style={{ marginLeft: '1em' }}
                                            >
                                                <Pencil /> Edit
                                            </a>
                                        </>
                                    )}
                                </div>
                            </BrowserOnly>
                        </div>
                        <PrintOnly>
                            <br />
                        </PrintOnly>
                        <div className={style.description}>
                            <Html content={newDescription} />
                        </div>
                        {this.renderTables(0)}
                        {this.renderButtons()}
                        {this.renderTables(secondTableIndex)}
                    </>
                )}
            </Page>
        );
    };

    renderAdditionalPages = () => {
        const devTags = this.state.data.graphql.reportMetrics;
        const filteredTables = Tables.filter(table => table.condition(devTags)).slice(2);

        const pageArray = [];
        // Max possible: 28 (Not including first 2)
        // 3 per page]
        if (filteredTables.length > 0) {
            const pageCount = Math.ceil(filteredTables.length / 3);
            // Separate Tables - 3 per page
            for (let i = 0; i < pageCount; i++) {
                pageArray.push(filteredTables.splice(0, 3));
            }
            if (pageArray.length > 0) {
                return (
                    <>
                        {pageArray.map((tables, i) => {
                            return (
                                <Page key={i}>
                                    {tables.map((table, id) => {
                                        const index = Tables.indexOf(table);
                                        return (
                                            <React.Fragment key={id}>
                                                {this.renderTables(index)}
                                            </React.Fragment>
                                        );
                                    })}
                                </Page>
                            );
                        })}
                    </>
                );
            }
            return null;
        }
        return null;
    };

    renderCitation = () => {
        const { settings } = this.props;

        return (
            <div className={style.citation}>
                <h4>{settings.citationLabel}</h4>
                <p>{settings.citation}</p>
            </div>
        );
    };

    render() {
        return (
            <Loader loading={this.state.loading}>
                <div className={this.props.isPreview ? style.reportPreview : style.report}>
                    <Report
                        onRef={elem => {
                            this.report = elem;
                        }}
                        showPagination={this.props.isPreview}
                        downloadFilename={`CalculationReport_${this.state.displayWage}${this.state
                            .data &&
                            this.state.data.graphql.reportMetrics.SchoolName +
                                '_' +
                                this.state.data.graphql.completedDate}`}
                    >
                        {this.state.data && (
                            <>
                                {this.renderFirstPage()}
                                {this.renderAdditionalPages()}
                            </>
                        )}
                    </Report>
                    {this.renderCitation()}
                </div>
            </Loader>
        );
    }
}

export default reportTemplatePlayer({
    filterFields: [],
})(CostCalculatorReport);
