import React from 'react';
import PropTypes from 'prop-types';
import { NavBar } from 'cccisd-laravel-boilerplate';
import classnames from 'classnames';

import style from './style.css';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const isInIframe = window.location !== window.parent.location;
const getWpUrl = (path = '') => 'https://www.ruralsmh.com/' + path;

export default class Component extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: 'container',
    };

    getLiLink = (key, url, target, link) => {
        return (
            <li key={key}>
                <a href={url} target={target}>
                    {link}
                </a>
            </li>
        );
    };

    render() {
        const wpLinks = [
            this.getLiLink('home', getWpUrl(''), '_top', 'Welcome'),
            this.getLiLink('about', getWpUrl('about-us'), '_top', 'About Us'),
            this.getLiLink('resources', Boilerplate.url('/resourceCenter'), '_top', 'Resources'),
            this.getLiLink('contact', getWpUrl('contact'), '_top', 'Contact'),
        ];

        if (isInIframe) {
            return (
                <NavBar
                    className={classnames(this.props.className, style.navbar)}
                    logo={
                        <a href={getWpUrl()} target="_top" className={style.logo}>
                            {' '}
                        </a>
                    }
                    noLogoLink
                    showMenu={false}
                    allowSwitchRoles={false}
                >
                    {wpLinks}
                </NavBar>
            );
        }

        return (
            <NavBar
                className={classnames(this.props.className, style.navbar)}
                noLogoLink
                logo={
                    <a href={getWpUrl()} target="_top" className={style.logo}>
                        {' '}
                    </a>
                }
            >
                <li key="welcome">
                    <a className={style.navLink} href={getWpUrl()} target="_top">
                        Welcome
                    </a>
                </li>
                <li key="about">
                    <a className={style.navLink} href={getWpUrl('about-us')} target="_top">
                        About Us
                    </a>
                </li>
                <li key="resources">
                    <a className={style.navLink} href={Boilerplate.url('/resourceCenter')} target="_top">
                        Resources
                    </a>
                </li>
                <li key="contact">
                    <a className={style.navLink} href={getWpUrl('contact')} target="_top">
                        Contact
                    </a>
                </li>
            </NavBar>
        );
    }
}
