import React from 'react';
import PropTypes from 'prop-types';
import notification from 'cccisd-notification';
import CostCalculatorPlayer from '../../../vendor/reports/reportTemplates/costCalculator/Player';

const Appdefs = window.cccisd.appDefs;

const ReportRender = props => {
    const { handle } = props;
    const settings =
        Appdefs &&
        Appdefs.reports &&
        Appdefs.reports.list &&
        Appdefs.reports.list.find(report => report.id === handle);

    if (!settings) {
        notification({
            message: `Cannot find a report where field "id" is equal to "${handle}"`,
            type: 'danger',
        });
        return null;
    }

    const Player = CostCalculatorPlayer;

    return (
        <Player
            settings={settings.reportTemplateSettings}
            filters={[]}
            isPreview={false}
            {...props}
        />
    );
};

ReportRender.propTypes = {
    handle: PropTypes.string.isRequired,
    deploymentId: PropTypes.number,
    startNewSurvey: PropTypes.func,
};

export default ReportRender;
