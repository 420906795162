import React from 'react';

import PropTypes from 'prop-types';
import style from './style.css';

const Select = props => {
    let value = props.newDeploymentId ? props.newDeploymentId : props.value;
    return (
        <select
            className={`form-control ${style.selectBox}`}
            onChange={props.onSelectionChange}
            value={value}
        >
            {props.options.map(option => (
                <option
                    key={option.responseSet}
                    value={option.responseSet}
                    name={option.completedDate}
                >
                    {option.completedDate}
                </option>
            ))}
        </select>
    );
};

Select.propTypes = {
    options: PropTypes.array,
    onSelectionChange: PropTypes.func,
    value: PropTypes.number,
    newDeploymentId: PropTypes.number,
};

export default Select;
