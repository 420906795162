import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import { RegisterLoginForm, ActivationForm } from 'cccisd-laravel-nexus';
import axios from 'cccisd-axios';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import Notification from 'cccisd-notification';
import assignmentProgressIdQuery from './assignmentProgressId.graphql';

const Boilerplate = window.cccisd.boilerplate;

const RegisterLogin = props => {
    const [loading, setLoading] = useState(false);
    const [pawnData, setPawnData] = useState(null);
    const [view, setView] = useState('login');

    const onRegisterSubmit = async values => {
        setLoading(true);
        values.role = 'calcUser';
        values.username = values.username || values.email;

        const storeResponse = await axios.post(Boilerplate.route('api.nexus.pawn.store'), values);
        if (storeResponse) {
            if (storeResponse.data.errors && storeResponse.data.errors.username) {
                Notification({
                    message: storeResponse.data.errors.username[0],
                    type: 'danger',
                });
            }
            if (storeResponse.data.data) {
                const newPawnId = storeResponse.data.data.id;
                const newPawnHash = storeResponse.data.data.random_hash;
                const { pawnId, pawnHash } = props.user;
                const assignmentProgressId = await getAssignmentProgressId();

                if (assignmentProgressId) {
                    const moveResponse = await axios.post(
                        Boilerplate.route('app.cost.calculator.move'),
                        {
                            anonPawnId: pawnId,
                            anonPawnHash: pawnHash,
                            ccUserPawnId: newPawnId,
                            ccUserPawnHash: newPawnHash,
                            deploymentId: props.deploymentId,
                            assignmentProgressId,
                        }
                    );

                    if (moveResponse && moveResponse.status === 200) {
                        setPawnData({
                            pawnId: newPawnId,
                            pawnHash: newPawnHash,
                            activationToken: storeResponse.data.data.user.activation_token,
                        });
                        setView('activate');
                    }
                }
            }
        } else {
            Notification({
                message: 'An error occured trying to create a new user.',
                type: 'danger',
            });
        }
        setLoading(false);
    };

    const onLoginSuccess = async response => {
        // Transfer data to existing pawn
        const realPawnId = response.data.data.current_pawn.id;
        const realPawnHash = response.data.data.current_pawn.random_hash;
        const { pawnId, pawnHash } = props.user;
        const assignmentProgressId = await getAssignmentProgressId();

        const moveResponse = await axios.post(Boilerplate.route('app.cost.calculator.move'), {
            anonPawnId: pawnId,
            anonPawnHash: pawnHash,
            ccUserPawnId: realPawnId,
            ccUserPawnHash: realPawnHash,
            deploymentId: props.deploymentId,
            assignmentProgressId,
        });
        if (moveResponse) {
            if (moveResponse.data.errors && moveResponse.data.errors.deployment) {
                // modal.current.open();
                Notification({
                    message:
                        'This user already has progress. Please continue your existing Cost Calculator Survey',
                    type: 'warning',
                });

                setTimeout(() => {
                    window.location = Boilerplate.url('/costCalc');
                }, 4000);
            }

            if (moveResponse.data.status === 'success') {
                window.location = Boilerplate.url('/costCalc');
            }
        }
    };

    const getAssignmentProgressId = async () => {
        const assignmentResponse = await client.query({
            query: assignmentProgressIdQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId: props.user.pawnId,
            },
        });

        const assignmentProgressId =
            assignmentResponse &&
            assignmentResponse.data.roles.anyRole.assignmentProgress.assignmentProgressId;

        return assignmentProgressId;
    };

    return (
        <Loader loading={loading}>
            {view === 'login' && (
                <div className={style.registerLogin}>
                    <div className={style.header}>
                        <div className={style.registerSide}>
                            <i>Need to register?</i>
                            <a onClick={props.continueAsGuest}>Continue as guest.</a>
                        </div>
                        <div className={style.loginSide}>
                            <i>Already have an account?</i>
                        </div>
                    </div>
                    <RegisterLoginForm
                        registerProps={{
                            onRegisterSubmit,
                            title: 'Create an Account',
                        }}
                        loginProps={{
                            defaultRedirectUrl: '#',
                            onSuccess: onLoginSuccess,
                            title: 'Log In',
                            buttonText: 'Log In',
                        }}
                    />
                </div>
            )}
            {view === 'activate' && (
                <div className={style.activationForm}>
                    <ActivationForm activationToken={pawnData.activationToken} alsoLogin />
                </div>
            )}
        </Loader>
    );
};

RegisterLogin.propTypes = {
    user: PropTypes.object,
    continueAsGuest: PropTypes.func,
    deploymentId: PropTypes.number,
};

export default RegisterLogin;
